import React from 'react';
import Grid from '@mui/material/Grid';
import { getAllData } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import IncidentsCard from '../card/incidentCard';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IncidentListGrouped from './incidentListGrouped';

export default function IncidentsList() {
  const query = () => getAllData('incident');
  const { t } = useTranslation();
  const history = useHistory();

  const { data, error, isLoading, isError } = useQuery('incident', query);

  if (isLoading) {
    return <Loader />;
  }
  console.log(data);

  return (
    <>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Button fullWidth variant="contained" startIcon={<AddIcon />} size="large" color="neutral" onClick={() => history.push(`/user/incident/0/edit`)}>
          {t('NEW_INCIDENT')}
        </Button>
      </Grid>
      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '15',
        }}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {`${t('OPEN_INCIDENTS')}`}
          </Typography>
          <Box sx={{ m: 'auto', width: '70%', mb: '10px' }}>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Grid item xs={12}>
        {data?.map((incident) => {
          if (incident.end_date === null) {
            return <IncidentsCard key={incident.incident_id} incident={incident} withClick />;
          }
          return null;
        })}
      </Grid>
      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '15',
        }}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {`${t('CLOSED_INCIDENTS')}`}
          </Typography>
          <Box sx={{ m: 'auto', width: '70%', mb: '10px' }}>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Grid item xs={12}>
        <IncidentListGrouped incidents={data} />
      </Grid>
    </>
  );
}
