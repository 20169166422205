import React from 'react';
import Typography from '@mui/material/Typography';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';

import Loader from 'src/shared/defaultLoader';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useHistory } from 'react-router-dom';
export default function WeeklyPlanningView({ functionDetails, year, weekNumber }) {
  const { t } = useTranslation();

  const getQueryFn = async () => await get(`function/${functionDetails.function_id}/planning/${year}/${weekNumber}`);
  const { data, isLoading } = useQuery([`function/${functionDetails.function_id}/planning/${year}/${weekNumber}`], getQueryFn);

  const history = useHistory();

  const handleClick = (event, member_id) => {
    // console.log(member_id);
    history.push(`/user/member/${member_id}`);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Typography variant="h6" component="div">
        {functionDetails.function_name}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <List>
            {data?.map((planning, index) => {
              if (planning.member_id) {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemButton onClick={(event) => handleClick(event, planning.member_id)}>
                      <ListItemText primary={`${t('RANK_ABBR.' + planning.rank_abbreviation)} ${planning.firstname} ${planning.lastname}`} />
                    </ListItemButton>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemText primary="&nbsp;" />
                  </ListItem>
                );
              }
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
