import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import { DateUtiles } from 'src/services/dateUtiles';
import { DurationUtils } from 'src/services/durations';
import { useTranslation } from 'react-i18next';

export default function IncidentMaterialLine({ material, allOpen }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(allOpen);
  }, [allOpen]);
  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ pb: '5px', pt: '5px' }}>
        <ListItemText primary={`${material[0].material_code} - ${material[0].material_name}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {material?.map((line) => {
            return (
              <ListItem key={line.materials_vehicles_incidents_id} sx={{ pl: 4 }}>
                <ListItemText
                  sx={{ p: 0, m: 0 }}
                  primary={`${t(line.material_status)} - ${DateUtiles.toDateTime(line.start_date)}`}
                  secondary={`${line.end_date ? DateUtiles.toDateTime(line.end_date) : t('IN_PROGRESS')}  (${DurationUtils.toReadableDurationBetweenTwoDate(
                    line.start_date,
                    line?.end_date ?? ''
                  )})${line.vehicle_code ? `- (${line.vehicle_code} - ${line.vehicle_name})` : ''}`}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
