import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from 'react-i18next';
import api from 'src/api/axios';
import { useQueryClient } from 'react-query';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function AlarmGroupUpload({ onImportDone }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [feedBack, setFeedBack] = React.useState('');
  const [feedBackType, setFeedBackType] = React.useState('success');

  const handleClose = () => {
    setFeedBack('');
  };

  // const [selectedFile, setSelectedFile] = useState();
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const myRefname = useRef(null);

  const changeHandler = async (event) => {
    const formData = new FormData();
    if (event.target.files.length > 0) {
      formData.append('file', event.target.files[0]);
      try {
        await api.post('/user/alarm_group_import', formData);
        event.target.value = null;
        queryClient.invalidateQueries('users');
        setFeedBackType('success');
        setFeedBack(t('MEMBER_IMPORT_DONE'));

        onImportDone();
        return true;
      } catch (error) {
        event.target.value = null;
        setFeedBackType('error');
        setFeedBack(t('MEMBER_IMPORT_ERROR'));
        console.error(error);
        return false;
      }
    }
  };

  const handleSubmission = () => {
    // this.inputElement.click();
    // myRefname.current.focus();
    myRefname.current.click();
  };

  return (
    <div>
      <input type="file" name="file" onChange={changeHandler} ref={myRefname} style={{ display: 'none' }} />
      <div>
        <Button size="small" variant="contained" startIcon={<FileUploadIcon />} onClick={handleSubmission}>
          {t('ALARM_GROUP_IMPORT')}
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={feedBack !== ''}
          autoHideDuration={6000}
          onClose={() => {
            setFeedBack('');
          }}
        >
          <Alert
            onClose={() => {
              setFeedBack('');
            }}
            severity={feedBackType}
            sx={{ width: '100%' }}
          >
            {t(feedBack)}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
