import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useUIContext } from '../../contexts/ui/ui.context';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AppsIcon from '@mui/icons-material/Apps';
import EventIcon from '@mui/icons-material/Event';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import { AuthenticationService } from 'src/services/auth';

const drawerWidth = 240;

export default function Sidebar(props) {
  const { t } = useTranslation();

  const { isMenuOpen, toggleMenu } = useUIContext();
  const { window } = props;
  let { url } = useRouteMatch();

  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const drawer = (
    <>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <NavLink to={`${url}/calendar`} className="link">
            <ListItem button>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={t('CALENDAR')} />
            </ListItem>
          </NavLink>

          <NavLink to={`${url}`} className="link">
            <ListItem button>
              <ListItemIcon>
                <LocalFireDepartmentIcon />
              </ListItemIcon>
              <ListItemText primary={t('ALARM')} />
            </ListItem>
          </NavLink>

          <NavLink to={`${url}/apps`} className="link">
            <ListItem button>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary={t('OTHERS')} />
            </ListItem>
          </NavLink>
          {/* {isIC ? (
            <NavLink to={`${url}/alarms`} className="link">
              <ListItem button>
                <ListItemIcon>
                  <FireplaceIcon />
                </ListItemIcon>
                <ListItemText primary={t('INCIDENTS')} />
              </ListItem>
            </NavLink>
          ) : null} */}
        </List>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={isMenuOpen}
        onClose={() => toggleMenu()}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
}
