import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';

import MoreIcon from '@mui/icons-material/MoreVert';
import { useUIContext } from '../../contexts/ui/ui.context';
import i18n from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthenticationService } from 'src/services/auth';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AlarmoLogo from 'src/icons/alarmoLogo';
import SettingsIcon from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';
import { useQueryClient } from 'react-query';

import { DateUtiles } from 'src/services/dateUtiles';

export default function Topbar() {
  const history = useHistory();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();
  const helpParam = queryClient.getQueryData(['params/info']);
  const lastHelpUpdate = DateUtiles.dateDiffInDays(new Date(helpParam?.helpData?.last_update), new Date());
  const lastViewedVersion = helpParam?.infoViewData?.changelog ?? '1.0';
  const localConfig = queryClient.getQueryData(['local/config']);

  // console.log((localConfig?.version ?? '1.0') > lastViewedVersion);

  const goBack = () => {
    history.goBack();
  };

  const baseUrls = new Set(['/user', '/user/calendar', '/user/apps']);

  const { toggleMenu, appBarTitle } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOnMainScreen, setIsOnMainScreen] = React.useState(false);
  const [anchorLanguageMenuEl, setAnchorLanguageEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguageMenuOpen = Boolean(anchorLanguageMenuEl);

  React.useEffect(() => {
    setIsOnMainScreen(baseUrls.has(pathname));
  }, [pathname]);

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLanguageMenuClose = () => {
    setAnchorLanguageEl(null);
    handleMobileMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorLanguageEl(event.currentTarget);
  };

  const handleLogout = () => {
    AuthenticationService.logout();
    history.push('/');
  };

  const handleParam = () => {
    history.push('/user/params');
  };

  const handleHelp = () => {
    window.open(helpParam.helpData.help_url, '_blank');
  };
  const handleChangeLog = () => {
    history.push('/user/changelog');
  };

  const menuId = 'primary-search-account-menu';
  const languageMenuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <LanguageIcon />
        </IconButton>
        <p>{t('LANGUAGE')}</p>
      </MenuItem>

      <MenuItem onClick={handleChangeLog}>
        <IconButton size="large" color="inherit">
          <Badge variant="dot" color="badge" badgeContent={(localConfig?.version ?? '1.0') > lastViewedVersion ? 1 : 0}>
            <InfoIcon />
          </Badge>
        </IconButton>
        <p>{t('CHANGELOG')}</p>
      </MenuItem>
      <MenuItem onClick={handleParam}>
        <IconButton size="large" color="inherit">
          <SettingsIcon />
        </IconButton>
        <p>{t('PARAMETERS')}</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton size="large" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p>{t('LOGOUT')}</p>
      </MenuItem>
    </Menu>
  );

  const renderLanguageMenu = (
    <Menu
      anchorEl={anchorLanguageMenuEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={languageMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem
        onClick={() => {
          changeLanguage('fr');
          handleLanguageMenuClose();
        }}
      >
        Français
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeLanguage('de');
          handleLanguageMenuClose();
        }}
      >
        Deutsch
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeLanguage('en');
          handleLanguageMenuClose();
        }}
      >
        English
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isOnMainScreen ? null : (
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => goBack()}>
              <ArrowBackIosNewIcon />
            </IconButton>
          )}

          {appBarTitle === '' || appBarTitle === 'Alarmo' ? (
            <AlarmoLogo style={{ height: '2em', color: 'blue' }} />
          ) : (
            <Typography variant="h6" noWrap component="div" sx={{ display: { sm: 'block' } }}>
              {appBarTitle}
            </Typography>
          )}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" color="inherit" onClick={handleHelp}>
              <Badge variant="dot" color="badge" badgeContent={lastHelpUpdate < 10 ? 1 : 0}>
                <HelpIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" color="inherit" onClick={handleChangeLog}>
              <Badge variant="dot" color="badge" badgeContent={(localConfig?.version ?? '1.0') > lastViewedVersion ? 1 : 0}>
                <InfoIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="change language" color="inherit" onClick={handleLanguageMenuOpen}>
              <LanguageIcon />
            </IconButton>

            <IconButton size="large" color="inherit" onClick={handleParam}>
              <SettingsIcon />
            </IconButton>
            <IconButton size="large" color="inherit" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" color="inherit" onClick={handleHelp}>
              <Badge variant="dot" color="badge" badgeContent={lastHelpUpdate < 10 ? 1 : 0}>
                <HelpIcon />
              </Badge>
            </IconButton>

            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <Badge variant="dot" color="badge" badgeContent={(localConfig?.version ?? '1.0') > lastViewedVersion ? 1 : 0}>
                <MoreIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderLanguageMenu}
    </>
  );
}
