import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Groups from 'src/modules/user/pages/groups/groups';
import Specializations from 'src/modules/user/pages/specialization/specializations';
import { useUIContext } from '../../contexts/ui/ui.context';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function MemberList({ data, noToggleButton, forceAllView = false }) {
  const { memberListCurrentOption, setMemberListCurrentOption } = useUIContext();
  const history = useHistory();
  const { t } = useTranslation();
  const handleClick = (event, member_id) => {
    // console.log(member_id);
    history.push(`/user/member/${member_id}`);
  };

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setMemberListCurrentOption(newType);
    }
  };

  return (
    <Grid container spacing={2}>
      {noToggleButton ? null : (
        <Grid item xs={12}>
          <ToggleButtonGroup color="primary" value={memberListCurrentOption} exclusive fullWidth onChange={handleChange}>
            <ToggleButton value="ALL">{t('ALL')}</ToggleButton>
            <ToggleButton value="GROUPS">{t('GROUPS')}</ToggleButton>
            <ToggleButton value="SPECIALIZATIONS">{t('SPECIALIZATIONS')}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      <Grid item xs={12}>
        {memberListCurrentOption === 'ALL' || forceAllView ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {data?.map((member) => {
                  return (
                    <TableRow
                      key={member.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      hover
                      // onClick={(event) => handleClick(event, member.id)}
                      role="checkbox"
                    >
                      <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }} onClick={(event) => handleClick(event, member.id)}>
                        {t('RANK_ABBR.' + member.rank_abbreviation)}
                      </TableCell>
                      <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }} onClick={(event) => handleClick(event, member.id)}>
                        {member.firstname}
                      </TableCell>
                      <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }} onClick={(event) => handleClick(event, member.id)}>
                        {member.lastname}
                      </TableCell>
                      <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                        <a href={`tel://${member.phone}`}>
                          <LocalPhoneIcon />
                        </a>
                      </TableCell>
                      <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                        <a href={`https://api.whatsapp.com/send?phone=${member.phone.replace(/[^0-9]/gi, '')}`}>
                          <WhatsAppIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        {memberListCurrentOption === 'GROUPS' && !forceAllView ? <Groups /> : null}
        {memberListCurrentOption === 'SPECIALIZATIONS' && !forceAllView ? <Specializations /> : null}
      </Grid>
    </Grid>
  );
}
