import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Typography from '@mui/material/Typography';
import { DateUtiles } from 'src/services/dateUtiles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function YearSelector({ baseDate: pBaseDate, onYearChange }) {
  const { t } = useTranslation();

  const [baseDate, setBaseDate] = useState(pBaseDate);
  const [YearTitle, setYearTitle] = useState('');

  useEffect(() => {
    setYearTitle(baseDate.getFullYear());
  }, [baseDate]);

  const updateBaseDate = (delta) => {
    const year = baseDate.getFullYear();
    const month = baseDate.getMonth();
    const day = baseDate.getDate();
    const newBaseDate = new Date(year + delta, month, day);
    setBaseDate(newBaseDate);
    onYearChange(newBaseDate);
  };
  return (
    <Item>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <IconButton aria-label="delete" size="large" onClick={() => updateBaseDate(-1)}>
          <NavigateBeforeIcon fontSize="inherit" />
        </IconButton>

        <Stack>
          <Typography variant="h6" component="div">
            {YearTitle}
          </Typography>
        </Stack>

        <IconButton aria-label="delete" size="large" onClick={() => updateBaseDate(1)}>
          <NavigateNextIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    </Item>
  );
}
