import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import api from 'src/api/axios';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { getAllData } from 'src/api/api';
import TableauSection from '../../components/tableau-section/tableau-section.component';
import { useDisplayContext } from '../../contexts/display/display.context';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TableauSpecialization from '../../components/tableau-specialization/tableau-specialization.component';
import TableauGroup from '../../components/tableau-group/tableau-group.component';
import TableauReceipt from '../../components/tableau-receipt/tableau-receipt';
import TableauAvailabilitySummary from '../../components/tableau-availability-summary/tableau-availability-summary';
import TableauWorkforce from '../../components/tableau-workforce/tableau-workforce';
import TableauVehicle from '../../components/tableau-vehicle/tableau-vehicle';
import BoardFooter from './board-footer';
import BlocOnDuty from '../../components/bloc-on-duty/bloc-on-duty';
import TableauMap from '../../components/tableau-map/tableau-map.component';

const Item = styled(TableauSection)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MasoneryColumnV = ({ columnNumber, blocData, hasOpenIncident, data, openIncidentData }) => {
  return (
    <>
      {blocData.map((bloc, blocKey) => {
        if (bloc.column_number !== columnNumber) {
          return null;
        }
        if (bloc.bloc_display_on === 'STANDBY' && hasOpenIncident) {
          return null;
        }
        if (bloc.bloc_display_on === 'INCIDENT' && !hasOpenIncident) {
          return null;
        }
        switch (bloc.bloc_type) {
          case 'SECTION':
            return data[bloc.bloc_param.section_id] ? (
              <TableauSection
                key={blocKey}
                sectionName={data[bloc.bloc_param.section_id].section_name}
                nameSuffix={bloc.bloc_param.suffix}
                namePrefix={bloc.bloc_param.prefix}
                section={data[bloc.bloc_param.section_id].members}
                displayAvailability={bloc.bloc_param.availability}
              />
            ) : null;
          case 'FUNCTION':
            return (
              <TableauSpecialization
                key={blocKey}
                title={bloc.bloc_name}
                specializationId={bloc.bloc_param.specialization_id}
                nameSuffix={bloc.bloc_param.suffix}
                namePrefix={bloc.bloc_param.prefix}
                datas={data}
                displayAvailability={bloc.bloc_param.availability}
              />
            );
          case 'GROUP':
            return (
              <TableauGroup
                key={blocKey}
                title={bloc.bloc_name}
                groupId={bloc.bloc_param.group_id}
                nameSuffix={bloc.bloc_param.suffix}
                namePrefix={bloc.bloc_param.prefix}
                datas={data}
                displayAvailability={bloc.bloc_param.availability}
              />
            );
          case 'RECEIPTS':
            return openIncidentData.map((incident) => {
              return (
                <TableauReceipt
                  key={blocKey}
                  title={bloc.bloc_name}
                  nameSuffix={bloc.bloc_param.suffix}
                  namePrefix={bloc.bloc_param.prefix}
                  datas={data}
                  incident={incident}
                />
              );
            });

          case 'AVAILABILITY_SUMMARY':
            return <TableauAvailabilitySummary key={blocKey} title={bloc.bloc_name} datas={data} />;
          case 'WORKFORCE':
            return <TableauWorkforce key={blocKey} title={bloc.bloc_name} />;
          case 'VEHICLES':
            return <TableauVehicle key={blocKey} title={bloc.bloc_name} />;
          case 'ON_DUTY':
            return <BlocOnDuty key={blocKey} title={bloc.bloc_name} datas={data} />;
          case 'MAP':
            return <TableauMap key={blocKey} title={bloc.bloc_name} />;
          case 'SHIFT':
            return bloc.elements.map((element, index) => {
              let limit = 0;
              if (bloc.bloc_param.number_to_display) {
                limit = parseInt(bloc.bloc_param.number_to_display);
              }
              if (bloc.bloc_param.number_to_shift > index) {
                return null;
              }

              if (limit !== 0 && index - bloc.bloc_param.number_to_shift >= limit) {
                return null;
              }

              if (element.section_id) {
                return data[element.section_id] ? (
                  <TableauSection
                    key={blocKey + '' + element.section_id}
                    sectionName={data[element.section_id].section_name}
                    nameSuffix={bloc.bloc_param.suffix}
                    namePrefix={bloc.bloc_param.prefix}
                    section={data[element.section_id].members}
                    isPrimary={index === 0}
                    displayAvailability={bloc.bloc_param.availability}
                  />
                ) : null;
              }
              if (element.group_id) {
                return (
                  <TableauGroup
                    key={blocKey + '' + element.group_id}
                    title=""
                    groupId={element.group_id}
                    nameSuffix={bloc.bloc_param.suffix}
                    namePrefix={bloc.bloc_param.prefix}
                    datas={data}
                    displayAvailability={bloc.bloc_param.availability}
                  />
                );
              }
              return null;
            });

          default:
            return null;
        }
      })}
    </>
  );
};
const MasoneryColumn = React.memo(MasoneryColumnV);

export default function Boards({ noLogo = false }) {
  const [hasOpenIncident, setHasOpenIncident] = React.useState();
  //const hasOpenIncident = false;
  //const setHasOpenIncident = () => {};
  let { id: boardIdParam } = useParams();
  const boardId = boardIdParam || 1;
  const { setAssignation } = useDisplayContext();

  const fetchData = async () => {
    try {
      const result = await api.get('/board');

      if (result.status !== 200) {
        throw new Error(`Can't retriev display list`);
      }
      return result.data;
    } catch (error) {
      throw new Error(`Can't retriev display  list`);
    }
  };

  const { data, isLoading } = useQuery('display', fetchData, {
    refetchInterval: 10000,
  });

  const query = () => getAllData('function');
  const { isLoading: functionIsLoading } = useQuery('function', query, { onSuccess: (data) => setAssignation(data) });

  const queryBloc = () => getAllData(`board/${boardId}/bloc`);
  const { data: blocData, isLoading: blocIsLoading } = useQuery(`display_board/${boardId}/bloc`, queryBloc, {
    refetchInterval: 10000,
  });

  const queryOpenIncident = () => getAllData(`incident/open`);
  const { data: openIncidentData, isLoading: incidentIsLoading } = useQuery(`incident/open`, queryOpenIncident, {
    refetchInterval: 10000,
    onSuccess: (data) => {
      setHasOpenIncident(data.length > 0);
    },
  });

  if (isLoading || functionIsLoading || blocIsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={{ width: '100%', minHeight: '100vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexFlow: 'column', minHeight: '98vh' }}>
              <MasoneryColumn columnNumber={1} blocData={blocData} hasOpenIncident={hasOpenIncident} data={data} openIncidentData={openIncidentData} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexFlow: 'column', minHeight: '98vh' }}>
              <MasoneryColumn columnNumber={2} blocData={blocData} hasOpenIncident={hasOpenIncident} data={data} openIncidentData={openIncidentData} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexFlow: 'column', minHeight: '98vh' }}>
              <MasoneryColumn columnNumber={3} blocData={blocData} hasOpenIncident={hasOpenIncident} data={data} openIncidentData={openIncidentData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {noLogo ? null : <BoardFooter />}
    </>
  );
}
