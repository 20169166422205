import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { updateData, createData, deleteData, getData, getAllData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AlertDialog from 'src/shared/sharedModal';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';

const BLOC_TYPE = [
  { code: 'SECTION', name: 'SECTION' },
  { code: 'SHIFT', name: 'SHIFT' },
  { code: 'FUNCTION', name: 'FUNCTION' },
  { code: 'GROUP', name: 'GROUP_NAME' },
  { code: 'RECEIPTS', name: 'RECEIPTS' },
  { code: 'AVAILABILITY_SUMMARY', name: 'AVAILABILITY_SUMMARY' },
  { code: 'WORKFORCE', name: 'WORKFORCE' },
  { code: 'VEHICLES', name: 'VEHICLES' },
  { code: 'ON_DUTY', name: 'ON_DUTY' },
  { code: 'MAP', name: 'MAP' },
];

const DISPLAY_AVAILABILITY_TYPE = [
  { code: 'ALL', name: 'ALL' },
  { code: 'AVAILABLE_ONLY', name: 'AVAILABLE_ONLY' },
  { code: 'UNAVAILABLE_ONLY', name: 'UNAVAILABLE_ONLY' },
];

const DISPLAY_ON_TYPE = [
  { code: 'ALL', name: 'DISPLAY_ON_ALL' },
  { code: 'STANDBY', name: 'DISPLAY_ON_STANDBY' },
  { code: 'INCIDENT', name: 'DISPLAY_ON_INCIDENT' },
];

export default function DisplayBoardBloc() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentBlocType, setCurrentBlocType] = useState('SECTION');
  let { id } = useParams();
  let { board_id } = useParams();

  const getQueryFn = async ({ queryKey }) => await getData(`display_board/${board_id}/bloc`, { queryKey });
  const createQueryFn = async (data) => await createData(`display_board/${board_id}/bloc`, data);
  const updateQueryFn = async ({ id, ...data }) => await updateData(`display_board/${board_id}/bloc`, { id, ...data });
  const deleteQueryFn = async ({ id, ...data }) => await deleteData(`display_board/${board_id}/bloc`, { id, ...data });

  const getSectionsQueryFn = async () => await getAllData('section');
  const { data: sections, isLoading: sectionIsLoading } = useQuery('sections', getSectionsQueryFn);

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationsIsLoading } = useQuery('specializations', getSpecializationQueryFn);

  const getGroupQueryFn = async () => await getAllData('group');
  const { data: groups, isLoading: groupsIsLoading } = useQuery('groups', getGroupQueryFn);

  const getShiftQueryFn = async () => await getAllData('display_shift');
  const { data: displayShifts, isLoading: displayShiftIsLoading } = useQuery('display_shift', getShiftQueryFn);

  const { data, error, isLoading, isError } = useQuery([`display_board/${board_id}/bloc`, { id }], getQueryFn, {
    onSuccess: (data) => {
      if (data.bloc_type) {
        setCurrentBlocType(data.bloc_type);
      }
    },
  });
  const { register, handleSubmit, control, watch } = useForm();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'bloc_type') {
        setCurrentBlocType(value.bloc_type);
      }
    });
    // console.log(value, name, type)});
    return () => subscription.unsubscribe();
  }, [watch]);

  const history = useHistory();

  const queryClient = useQueryClient();
  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([`display_board/${board_id}/bloc`, { id: id }], data);
    },
  });
  const { mutateAsync: mutateAsyncCreate } = useMutation(createQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([`display_board/${board_id}/bloc`, { id: id }], data);
      queryClient.setQueryData([`display_board/${board_id}/bloc`, { id: '0' }], {});
    },
  });
  const { mutateAsync: mutateAsyncDelete } = useMutation(deleteQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([`display_board/${board_id}/bloc`, { id: id }], {});
    },
  });

  async function deleteCurrentRecord() {
    await mutateAsyncDelete({ ...data, id });
    history.goBack();
  }

  if (isLoading || sectionIsLoading || displayShiftIsLoading || specializationsIsLoading || groupsIsLoading) {
    return <Loader />;
  }

  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    if (id !== '0') {
      await mutateAsyncUpdate({ ...data, id });
    } else {
      await mutateAsyncCreate(data);
    }
    history.goBack();
  });

  const SectionParams = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.section_id"
            name="bloc_param.section_id"
            label={t('SECTION')}
            control={control}
            defaultValue={data.bloc_param?.section_id || ''}
            variant="standard"
          >
            {sections.map((section) => {
              return (
                <MenuItem key={section.section_id} value={section.section_id}>
                  {section.section_name}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.availability"
            name="bloc_param.availability"
            label={t('BLOC_PARAM_AVAILABILITY_TYPE')}
            control={control}
            defaultValue={data.bloc_param?.availability || 'ALL'}
            variant="standard"
          >
            {DISPLAY_AVAILABILITY_TYPE.map((type) => {
              return (
                <MenuItem key={type.code} value={type.code}>
                  {t(type.name)}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.prefix')}
              autoComplete="off"
              id="bloc_param.prefix"
              name="bloc_param.prefix"
              label={t('PREFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.prefix || ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.suffix')}
              autoComplete="off"
              id="bloc_param.suffix"
              name="bloc_param.suffix"
              label={t('SUFFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.suffix || ''}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  const FunctionParams = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.specialization_id"
            name="bloc_param.specialization_id"
            label={t('FUNCTION')}
            control={control}
            defaultValue={data.bloc_param?.specialization_id || ''}
            variant="standard"
          >
            {specializations.map((specialization) => {
              return (
                <MenuItem key={specialization.specialization_id} value={specialization.specialization_id}>
                  {specialization.specialization_name}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.availability"
            name="bloc_param.availability"
            label={t('BLOC_PARAM_AVAILABILITY_TYPE')}
            control={control}
            defaultValue={data.bloc_param?.availability || 'ALL'}
            variant="standard"
          >
            {DISPLAY_AVAILABILITY_TYPE.map((type) => {
              return (
                <MenuItem key={type.code} value={type.code}>
                  {t(type.name)}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.prefix')}
              autoComplete="off"
              id="bloc_param.prefix"
              name="bloc_param.prefix"
              label={t('PREFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.prefix || ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.suffix')}
              autoComplete="off"
              id="bloc_param.suffix"
              name="bloc_param.suffix"
              label={t('SUFFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.suffix || ''}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  const GroupParams = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.group_id"
            name="bloc_param.group_id"
            label={t('GROUP_NAME')}
            control={control}
            defaultValue={data.bloc_param?.group_id || ''}
            variant="standard"
          >
            {groups.map((group) => {
              return (
                <MenuItem key={group.group_id} value={group.group_id}>
                  {group.group_name}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.availability"
            name="bloc_param.availability"
            label={t('BLOC_PARAM_AVAILABILITY_TYPE')}
            control={control}
            defaultValue={data.bloc_param?.availability || 'ALL'}
            variant="standard"
          >
            {DISPLAY_AVAILABILITY_TYPE.map((type) => {
              return (
                <MenuItem key={type.code} value={type.code}>
                  {t(type.name)}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.prefix')}
              autoComplete="off"
              id="bloc_param.prefix"
              name="bloc_param.prefix"
              label={t('PREFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.prefix || ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.suffix')}
              autoComplete="off"
              id="bloc_param.suffix"
              name="bloc_param.suffix"
              label={t('SUFFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.suffix || ''}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  const ShiftParams = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.display_shift_id"
            name="bloc_param.display_shift_id"
            label={t('SHIFT')}
            control={control}
            defaultValue={data.bloc_param?.display_shift_id || ''}
            variant="standard"
          >
            {displayShifts.map((shift) => {
              return (
                <MenuItem key={shift.display_shift_id} value={shift.display_shift_id}>
                  {shift.shift_name}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="bloc_param.availability"
            name="bloc_param.availability"
            label={t('BLOC_PARAM_AVAILABILITY_TYPE')}
            control={control}
            defaultValue={data.bloc_param?.availability || 'ALL'}
            variant="standard"
          >
            {DISPLAY_AVAILABILITY_TYPE.map((type) => {
              return (
                <MenuItem key={type.code} value={type.code}>
                  {t(type.name)}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.number_to_display')}
              autoComplete="off"
              id="bloc_param.number_to_display"
              name="bloc_param.number_to_display"
              label={t('NUMBER_TO_DISPLAY')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.number_to_display || ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.number_to_shift')}
              required
              autoComplete="off"
              id="bloc_param.number_to_shift"
              name="bloc_param.number_to_shift"
              label={t('NUMBER_TO_SHIFT')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.number_to_shift || 0}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.prefix')}
              autoComplete="off"
              id="bloc_param.prefix"
              name="bloc_param.prefix"
              label={t('PREFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.prefix || ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('bloc_param.suffix')}
              autoComplete="off"
              id="bloc_param.suffix"
              name="bloc_param.suffix"
              label={t('SUFFIX')}
              fullWidth
              variant="standard"
              defaultValue={data.bloc_param?.suffix || ''}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t('DETAILS')}
        </Typography>
        <form className="userUpdateForm" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('bloc_name')}
                  required
                  autoComplete="off"
                  id="bloc_name"
                  name="bloc_name"
                  label={t('NAME')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.bloc_name}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ReactHookFormSelect
                register={register}
                id="bloc_display_on"
                name="bloc_display_on"
                label={t('BLOC_DISPLAY_ON')}
                control={control}
                defaultValue={data.bloc_display_on || 'ALL'}
                variant="standard"
              >
                {DISPLAY_ON_TYPE.map((type) => {
                  return (
                    <MenuItem key={type.code} value={type.code}>
                      {t(type.name)}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('column_number')}
                  required
                  autoComplete="off"
                  id="column_number"
                  name="column_number"
                  label={t('COLUMN_NUMBER')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.column_number}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('line_number')}
                  required
                  autoComplete="off"
                  id="line_number"
                  name="line_number"
                  label={t('LINE_NUMBER')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.line_number}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <ReactHookFormSelect
                register={register}
                id="bloc_type"
                name="bloc_type"
                label={t('BLOC_TYPE')}
                control={control}
                defaultValue={data.bloc_type || 'SECTION'}
                variant="standard"
              >
                {BLOC_TYPE.map((type) => {
                  return (
                    <MenuItem key={type.code} value={type.code}>
                      {t(type.name)}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('bloc_order')}
                  required
                  autoComplete="off"
                  id="bloc_order"
                  name="bloc_order"
                  label={t('BLOC_ORDER')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.bloc_order}
                />
              </FormControl>
            </Grid> */}
            {currentBlocType === 'SECTION' ? <SectionParams /> : null}
            {currentBlocType === 'SHIFT' ? <ShiftParams /> : null}
            {currentBlocType === 'FUNCTION' ? <FunctionParams /> : null}
            {currentBlocType === 'GROUP' ? <GroupParams /> : null}

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Stack direction="row" spacing={1}>
                  <Button type="submit" variant="contained">
                    {t('SAVE')}
                  </Button>
                  {id !== '0' ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setOpen(true);
                        // deleteCurrentRecord;
                      }}
                    >
                      {t('DELETE')}
                    </Button>
                  ) : null}
                  <AlertDialog
                    open={open}
                    setOpen={setOpen}
                    Message={t('Êtes-vous sûr de vouloir supprimer cet enregistrement?')}
                    onConfirm={() => deleteCurrentRecord()}
                    AcceptBtn={t('YES')}
                    CancelBtn={t('CANCEL')}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}
