import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function AlertDialog({ open, setOpen, onConfirm, Message, CancelBtn, AcceptBtn }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{Message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{CancelBtn}</Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            autoFocus
          >
            {AcceptBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
