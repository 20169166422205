import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import { EVENT_TYPE } from 'src/constants/constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import frCHLocale from 'date-fns/locale/fr-CH';
import Stack from '@mui/material/Stack';
import store from '../quickEntry.vanilla.store';
import { useHistory } from 'react-router-dom';

export default function InterventionLogEntry() {
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];
  const quickEntry = store.getState().logEntry;
  const { register, watch, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id, interventionId } = useParams();

  const getQueryFn = async ({ queryKey }) => await getData('incident-logs/entry', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['incident-logs/entry', { id: id }], getQueryFn, {
    onSuccess: (data) => {
      if (data.event_date) {
        setEventDate(new Date(data.event_date));
        setValue('event_date', data.event_date);
        setValue('subject', data.subject);
        setValue('comment', getCommentFromLog(data));
      }
    },
  });

  const defaultEventDate = new Date();
  defaultEventDate.setMilliseconds(0);
  const [eventDate, setEventDate] = React.useState(defaultEventDate);

  React.useEffect(() => {
    setValue('incident_id', interventionId);
    setValue('event_date', defaultEventDate);
    if (quickEntry) {
      setValue('subject', quickEntry.subject);
      setValue('comment', quickEntry.comment);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  let comment = getCommentFromLog(data);

  const beforeSubmit = (dataToSave) => {
    console.log(dataToSave);
    let newComment = '';
    if (isValidJsonObject(data.comment)) {
      const jsonObject = JSON.parse(data.comment);
      jsonObject.comment = dataToSave.comment;
      dataToSave.comment = JSON.stringify(jsonObject);
    }
  };

  return (
    <GenericCRUD
      objectName={'incident-logs'}
      handleSubmit={handleSubmit}
      beforeSubmit={beforeSubmit}
      data={data}
      returnPath={`/${module}/intervention/${interventionId}/log`}
    >
      {/* <Grid item xs={12}>
        <ReactHookFormSelect
          register={register}
          id="incident_type"
          name="incident_type"
          label={t('INCIDENT_TYPE')}
          control={control}
          defaultValue={data.incident_type}
          variant="standard"
        >
          {EVENT_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid> */}
      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('subject')}
            autoComplete="off"
            id="subject"
            name="subject"
            label={t('INCIDENT_LOG_SUBJECT')}
            fullWidth
            variant="standard"
            defaultValue={data.subject}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('comment')}
            required
            autoComplete="off"
            id="comment"
            name="comment"
            label={t('COMMENT')}
            multiline
            rows={5}
            fullWidth
            variant="standard"
            defaultValue={comment}
          />
        </FormControl>
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frCHLocale}>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <DateTimePicker
              label={t('EVENT_DATE')}
              ampm={false}
              value={eventDate}
              onChange={(newValue) => {
                setEventDate(newValue);
                setValue('event_date', newValue);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </Stack>
        </Grid>
      </LocalizationProvider>
    </GenericCRUD>
  );
}

function isValidJsonObject(str) {
  try {
    // Attempt to parse the string as JSON
    const parsed = JSON.parse(str);

    // Check if the parsed value is an object and not null (because typeof null is "object")
    return typeof parsed === 'object' && parsed !== null;
  } catch (e) {
    // If JSON.parse throws an error, it means the string is not valid JSON
    return false;
  }
}

function getCommentFromLog(data) {
  let comment = '';
  if (isValidJsonObject(data.comment)) {
    const jsonObject = JSON.parse(data.comment);
    comment = jsonObject.comment;
  } else {
    comment = data.comment;
  }
  return comment;
}
