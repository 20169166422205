import moment from 'moment';

const toReadableDuration = (date) => {
  const now = moment(new Date());
  const receipt = moment(date);
  const duration = moment.duration(now.diff(receipt));

  let result = '';
  const days = duration.get('days');
  if (days === 1) {
    result += ' 1j ';
  } else if (days > 1) {
    result += ` ${days}j `;
  }

  const hours = duration.get('hours');
  if (hours === 1) {
    result += ' 1h ';
  } else if (hours > 1) {
    result += ` ${hours}h `;
  }

  const minutes = duration.get('minutes');
  if (minutes <= 1) {
    result += ` ${minutes}m `;
  } else if (minutes > 1) {
    result += ` ${minutes}m `;
  }

  return result;
};

const toReadableDurationBetweenTwoDate = (datefrom, dateTo) => {
  let now = null;
  if (dateTo === '') {
    now = moment(new Date());
  } else {
    now = moment(dateTo);
  }

  const receipt = moment(datefrom);
  const duration = moment.duration(now.diff(receipt));

  let result = '';
  const days = duration.get('days');
  if (days === 1) {
    result += ' 1j ';
  } else if (days > 1) {
    result += ` ${days}j `;
  }

  const hours = duration.get('hours');
  if (hours === 1) {
    result += ' 1h ';
  } else if (hours > 1) {
    result += ` ${hours}h `;
  }

  const minutes = duration.get('minutes');
  if (minutes <= 1) {
    result += ` ${minutes}m `;
  } else if (minutes > 1) {
    result += ` ${minutes}m `;
  }

  return result;
};

export const DurationUtils = {
  toReadableDuration,
  toReadableDurationBetweenTwoDate,
};
