import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

export default function DisplayBoardBlocList() {
  let { id } = useParams();
  let { url } = useRouteMatch();

  const { t } = useTranslation();
  const columns = [
    { field: 'display_board_id', headerName: 'ID', hide: true },
    {
      field: 'bloc_name',
      headerName: t('BLOC_NAME'),
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: 'bloc_order',
      headerName: t('ORDER'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'column_number',
      headerName: t('COLUMN_NUMBER'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'line_number',
      headerName: t('LINE_NUMBER'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={`display_board/${id}/bloc`} columns={columns} detailLink={url.slice(0, -1) + ''} rowId={'display_board_bloc_id'} />;
}
