import React from 'react';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import RoomIcon from '@mui/icons-material/Room';
import LinkIcon from '@mui/icons-material/Link';
import { useUIContext } from '../../contexts/ui/ui.context';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Links() {
  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('LINKS'));
  }, []);

  const query = () => getAllData('link');

  const { data, error, isLoading, isError } = useQuery('alarm/receipt', query);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      {data.map((link) => {
        let icon = LinkIcon;
        switch (link.link_type) {
          case 'MAP':
            icon = RoomIcon;
            break;
          case 'LINK':
            icon = LinkIcon;
            break;
          default:
            break;
        }
        return (
          <Grid key={link.link_id} item xs={12}>
            {link.link_type === 'SEPARATOR' ? (
              <Box
                sx={{
                  backgroundColor: 'primary.main',
                  textAlign: 'center',
                  color: 'primary.contrastText',
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" gutterBottom component="div">
                  {link.link_name}
                </Typography>
              </Box>
            ) : (
              <Button icon={icon} iconColor={Colors.button_default_icon} bgColor={Colors.button_default} onClick={() => window.open(link.link_url, '_blank')}>
                {link.link_name}
              </Button>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
