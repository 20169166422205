import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function VtractIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="297mm" height="115mm" viewBox="0 0 297 115">
      <g transform="translate(710.52 -31.097) scale(2.9978)">
        <path d="M-179.04 41.128l5.053 1.912 5.006-1.763-2.91-3.02-4.884.282zM-207.62 41.128l5.053 1.912 7.42-1.912-3.87-5.053-8.603.546z"></path>
        <rect width="17.573" height="0.875" x="-195.95" y="42.26" fill="#cecece" rx="0.5" ry="0.5"></rect>
        <rect width="2.233" height="0.748" x="-187.5" y="27.348" fill="#0056ff" fillOpacity="0.471" stroke="#003eff" strokeWidth="0.13" rx="0.112"></rect>
        <rect width="0.971" height="0.35" x="-186.92" y="28.12" fill="#3d3d3d" rx="0.049"></rect>
        <path
          fill="#f0321a"
          stroke="#373737"
          strokeWidth="0.132"
          d="M-195.97 28.549c-.596.012-1.247.008-1.535 1.447l-.042 3.554-16.016-.137.015 6.048 1.428 2.343 4.846.241c1.35-6.573 9.749-4.444 9.917.19l19.251.06c1.93-6.227 8.398-3.343 8.726-.069 1.442-.13 2.908.323 3.121-.614l-.024-4.689-.254-1.27c-.829-2.1-8.427-2.307-11.537-3.073-.001 0-4.808-4.172-7.624-4.054z"
        ></path>
        <rect width="1.83" height="0.544" x="-195.42" y="34.422" fill="#272727" rx="0.174"></rect>
        <rect width="1.83" height="0.544" x="-186.95" y="34.554" fill="#272727" rx="0.174"></rect>
        <path
          fill="#48486e"
          stroke="#201b1a"
          strokeWidth="0.17"
          d="M-194.95 29.267l9.086.052c.414.002 4.94 1.513 6.255 3.45.241.266.055.84-.306.85-1.414-.057-13.071-.278-15.196-.455-.525-.035-.416-.238-.422-1.029l-.017-2.268c.102-.342.319-.592.6-.6z"
        ></path>
        <path fill="#fff" fillOpacity="0.411" d="M-196.68 38.285c8.688-.021 15.139 1.167 19.362 3.9-5.777-3.806-12.147-6.2-19.604-6.008z"></path>
        <path
          fill="#201b1a"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M-189.288 29.356l1.693-.027.36 4.023-1.747-.043z"
        ></path>
        <g transform="translate(-61.576 90.53) scale(.68822)">
          <circle cx="-204.4" cy="-68.215" r="5.19" fill="#272727" strokeWidth="0.271"></circle>
          <circle cx="-204.4" cy="-68.215" r="3.295" fill="#a2a2a2" strokeWidth="0.172"></circle>
          <circle cx="-204.4" cy="-68.215" r="1.433" fill="#363636" strokeWidth="0.075"></circle>
        </g>
        <g transform="translate(-33.001 90.53) scale(.68822)">
          <circle cx="-204.4" cy="-68.215" r="5.19" fill="#272727" strokeWidth="0.271"></circle>
          <circle cx="-204.4" cy="-68.215" r="3.295" fill="#a2a2a2" strokeWidth="0.172"></circle>
          <circle cx="-204.4" cy="-68.215" r="1.433" fill="#363636" strokeWidth="0.075"></circle>
        </g>
        <path
          fill="#201b1a"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.66"
          d="M-179.39 32.509l.04.606c-.043.892-1.118 1.035-1.122.631l-.028-1.305c-.085-.464.505-.47.71-.453.236.086.326.245.4.521z"
        ></path>
        <g strokeWidth="0.12">
          <path
            fill="#fff"
            stroke="#373737"
            d="M-213.54 39.439l-.711.083-.066 1.554 1.554 1.455 1.307.066.661-.661-1.29-.1zM-166.01 39.461c.862.155.46.764.286 2.093l-.584.707-2.686-.027-.041-1.695c-.048-.381.258-.5.504-.542l1.908-.161z"
          ></path>
          <path
            fill="#ececec"
            fillOpacity="0.966"
            stroke="#000"
            d="M-166.27 37.025l-2.332.017c-.945-.491-.702-1.05-.512-1.604.621-.376 1.432-.292 2.2-.315l.396.496z"
          ></path>
          <path
            fill="#ececec"
            fillOpacity="0.966"
            stroke="#373737"
            d="M-212.07 35.911c.233 1.078-.235 2.03-1.462 2.828-.016-.8-.029-2.684-.025-3.233l1.099.005c.14.01.28.024.388.4z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
