import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { get, post } from 'src/api/api';
import { useQuery } from 'react-query';
import WeekSelector from 'src/shared/weekSelector';
import Loader from 'src/shared/defaultLoader';
import WeeklyPlanning from './weeklyPlanning';
import { DateUtiles } from 'src/services/dateUtiles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import PlanningPushModal from './planningPushModal';
import useStore from './planning.store';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
  marginTop: theme.spacing(2),
}));

export default function Planning() {
  const { t } = useTranslation();
  const [feedBack, setFeedBack] = useState('');

  const [baseDate, setBaseDate] = useState(new Date());

  const getQueryFn = async ({ queryKey }) => await get('function/list/weekly');
  const { data, isLoading } = useQuery(['function/list/weekly'], getQueryFn);

  const plannings = useStore((state) => state.plannings);
  const planningChanged = useStore((state) => state.planningChanged);
  const setPlanningToSaved = useStore((state) => state.setPlanningToSaved);
  const emptyPlanning = useStore((state) => state.emptyPlanning);

  const pushPlanning = async (type) => {
    try {
      await post('function/planning/push', { type: type, baseDate: baseDate });
      setFeedBack('PLANNING_PUSHED_SUCESS');
    } catch (error) {
      console.error(error);
    }
  };

  const savePlanning = async () => {
    await post('function/planning', plannings);
    setFeedBack('PLANNING_SAVED_SUCESS');
    emptyPlanning();
    setPlanningToSaved();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WeekSelector baseDate={baseDate} onWeekChange={(newDate) => setBaseDate(newDate)} />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        {data.map((func) => {
          console.log(baseDate);
          let year = DateUtiles.getYear(DateUtiles.getMonday(baseDate));
          const weekNumber = DateUtiles.getWeekNumber(DateUtiles.getMonday(baseDate));
          if (weekNumber == 1) {
            year = DateUtiles.getYear(DateUtiles.getSunday(baseDate));
          }
          console.log(DateUtiles.getWeekNumber(DateUtiles.getMonday(baseDate)));
          return (
            <Item key={func.function_id}>
              <WeeklyPlanning functionDetails={func} year={year} weekNumber={weekNumber} pushIsDisabled={planningChanged} />
            </Item>
          );
        })}
      </Grid>

      <Grid item xs={6}>
        <Item>
          <Button disabled={!planningChanged} variant="contained" size="large" fullWidth onClick={savePlanning}>
            {t('SAVE')}
          </Button>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <PlanningPushModal onConfirm={pushPlanning} isDisabled={planningChanged} />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={feedBack !== ''}
            autoHideDuration={6000}
            onClose={() => {
              setFeedBack('');
            }}
          >
            <Alert
              onClose={() => {
                setFeedBack('');
              }}
              severity="success"
              sx={{ width: '100%' }}
            >
              {t(feedBack)}
            </Alert>
          </Snackbar>
        </Item>
      </Grid>
    </Grid>
  );
}
