import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './pages/home/home';
import Boards from './pages/boards/boards';
import { ThemeProvider } from '@mui/material/styles';
import theme from './display.theme';
import './display.scss';

export default function Admin() {
  let { path } = useRouteMatch();

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route path={`${path}/board/:id`}>
          <Boards />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}
