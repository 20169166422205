import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import MemberSelector from 'src/shared/memberSelector';

export default function Section() {
  const { register, handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('section', { queryKey });

  const inputRef = React.useRef();

  const { data, error, isLoading, isError } = useQuery(['section', { id }], getQueryFn, {
    onSuccess: (data) => {
      setValue('section_leader_id', data?.section_leader_id ?? null);
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'section'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('section_name')}
            required
            autoComplete="off"
            id="section_name"
            name="section_name"
            label={t('SECTION')}
            fullWidth
            variant="standard"
            defaultValue={data.section_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('section_order')}
            required
            id="section_order"
            name="section_order"
            label={t('ORDER_RANK')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.section_order}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('source_mapping')}
            id="source_mapping"
            name="source_mapping"
            label={t('SOURCE_MAPPING')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.source_mapping}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <MemberSelector
          defaultMemberId={data.section_leader_id}
          label={t('SECTION_LEADER')}
          onChange={(v) => {
            setValue('section_leader_id', v?.member_id ?? null);
          }}
          inputRef={inputRef}
        />
      </Grid>
    </GenericCRUD>
  );
}
