import React from 'react';
import { useTranslation } from 'react-i18next';
import WeekSelector from 'src/shared/weekSelector';
import AllPlanningAgenda from './allPlanningAgenda';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RegroupedPlanning from './regroupedPlanning';
import { useUIContext } from '../../contexts/ui/ui.context';

export default function AllPlanning() {
  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('OPERATIONAL_STATE'));
  }, []);
  const [baseDate, setBaseDate] = React.useState(new Date());

  const [agendaType, setAgendaType] = React.useState('FUNCTION');

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setAgendaType(newType);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WeekSelector baseDate={baseDate} onWeekChange={(newDate) => setBaseDate(newDate)} />
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup color="primary" value={agendaType} exclusive fullWidth onChange={handleChange}>
          <ToggleButton value="FUNCTION">{t('PER_FUNCTION')}</ToggleButton>
          <ToggleButton value="PERSON">{t('PER_PERSON')}</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {agendaType === 'PERSON' ? <AllPlanningAgenda baseDate={baseDate} type={'AVAILABILITY'} /> : <RegroupedPlanning baseDate={baseDate} />}
      </Grid>
    </Grid>
  );
}
