import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Colors from 'src/styles/variables';

export default function WorkforceEngagedSummary() {
  const { t } = useTranslation();

  const query = () => getAllData('alarm/receipt');
  const { data, error, isLoading, isError } = useQuery('alarm/receipt', query);

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  const getRolesQueryFn = async () => await getAllData('role');
  const { data: roles, isLoading: roleIsLoading } = useQuery('roles', getRolesQueryFn);

  if (isLoading || specializationIsLoading || roleIsLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  let rolesData = [];
  for (const role of roles) {
    const number = data.reduce((previousValue, currentValue) => {
      if (currentValue.roles) {
        if (currentValue.roles.includes(role.role_name)) {
          return previousValue + 1;
        } else {
          return previousValue;
        }
      }
    }, 0);
    if (number > 0 && role.is_on_summary) {
      rolesData.push({ name: role.role_abbreviation, number: number, color: role.color });
    }
  }
  // console.log(rolesData);

  let specData = [];
  for (const spec of specializations) {
    const number = data.reduce((previousValue, currentValue) => {
      if (currentValue.specializations) {
        if (currentValue.specializations.includes(spec.specialization_abbreviation)) {
          return previousValue + 1;
        } else {
          return previousValue;
        }
      }
    }, 0);
    if (number > 0 && spec.is_on_summary) {
      specData.push({ name: spec.specialization_name, number: number, color: spec.color });
    }
  }
  // console.log(rolesData);

  return (
    <Stack spacing={1}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: Colors.ALARM.darker }}></TableCell>
              <TableCell sx={{ p: 1 }}>{t('TOTAL_WORKFORCE_ENGAGED')}</TableCell>
              <TableCell align="right">{data.length}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {rolesData.map((m, index) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: m.color }}></TableCell>
                  <TableCell sx={{ p: 1 }}>{t(m.name)}</TableCell>
                  <TableCell align="right">{m.number}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {specData.map((m, index) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: m.color }}></TableCell>
                  <TableCell sx={{ p: 1 }}>{t(m.name)}</TableCell>
                  <TableCell align="right">{m.number}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
