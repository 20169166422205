import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import { EVENT_TYPE } from 'src/constants/constants';
import MemberSelector from 'src/shared/memberSelector';
import Button from '@mui/material/Button';
import { get } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';

export default function IncidentCardEdit() {
  const { register, watch, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();

  const isSquadLeaderOrHigher = AuthenticationService.checkUserMaxAccessLevel() > 80;

  const getQueryFn = async ({ queryKey }) => await getData('incident', { queryKey });
  const inputRef = React.useRef();
  const { data, error, isLoading, isError } = useQuery(['incidentDetail', { id }], getQueryFn, {
    onSuccess: (data) => {
      setValue('incident_commander_id', data?.incident_commander_id ?? null);
    },
  });

  let geolocationAvailable = false;
  if ('geolocation' in navigator) {
    geolocationAvailable = true;
  }

  const btnLocationClicked = async () => {
    if (geolocationAvailable) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          // console.log('Latitude is :', position.coords.latitude);
          // console.log('Longitude is :', position.coords.longitude);
          const currentPosition = await get('utils/revgeocode', { long: position.coords.longitude, lat: position.coords.latitude });
          // console.log(currentPosition);
          if (currentPosition.items.length > 0) {
            const geo = currentPosition.items[0].address;
            let newAlarmAddress = '';
            if (geo.label) {
              newAlarmAddress = `${geo.label}`;
            } else {
              newAlarmAddress = `${geo.street}${geo.houseNumber ? ' ' + geo.houseNumber : ''}, ${geo.postalCode} ${geo.city}`;
            }
            setValue('incident_address', newAlarmAddress);
          }
        },
        null,
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0,
        }
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'incident'} handleSubmit={handleSubmit} hideDelete={true} data={data}>
      <Grid item xs={12}>
        <ReactHookFormSelect
          disabled={!isSquadLeaderOrHigher}
          register={register}
          id="incident_type"
          name="incident_type"
          label={t('INCIDENT_TYPE')}
          control={control}
          defaultValue={data.incident_type}
          variant="standard"
        >
          {EVENT_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            disabled={!isSquadLeaderOrHigher}
            {...register('incident_description')}
            autoComplete="off"
            id="incident_description"
            name="incident_description"
            label={t('ALARM_MESSAGE')}
            multiline
            rows={3}
            fullWidth
            variant="standard"
            defaultValue={data.incident_description}
          />
        </FormControl>
      </Grid>

      <Grid item xs={geolocationAvailable ? 8 : 12}>
        {' '}
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('incident_address')}
            autoComplete="off"
            id="incident_address"
            name="incident_address"
            label={t('INCIDENT_ADDRESS')}
            fullWidth
            variant="standard"
            defaultValue={data.incident_address}
          />
        </FormControl>
      </Grid>
      {geolocationAvailable ? (
        <Grid item xs={4}>
          <Button sx={{ height: '46px', minHeight: '46px' }} variant="outlined" onClick={btnLocationClicked}>
            {t('GET_LOCATION')}
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <MemberSelector
          disabled={!isSquadLeaderOrHigher}
          defaultMemberId={data.incident_commander_id}
          label={t('INCIDENT_COMMANDER')}
          onChange={(v) => {
            setValue('incident_commander_id', v?.member_id ?? null);
          }}
          inputRef={inputRef}
        />
      </Grid>
    </GenericCRUD>
  );
}
