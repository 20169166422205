import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Admin from 'src/modules/admin/admin';
import User from 'src/modules/user/user';
import Display from './modules/display/display';
import Login from './modules/login/login';
import ProtectedRoute from 'src/shared/protectedRoute';
import { DisplayContextProvider } from 'src/modules/display/contexts/display/display.context';
import PasswordReset from './modules/login/password_reset';
import ForgottenPassword from './modules/login/forgotten_password';
import { useQuery } from 'react-query';
import { AuthenticationService } from 'src/services/auth';
import { get } from 'src/api/api';
import i18n from 'src/i18n/i18n';
import GlobalFeedback from './modules/shared/globalFeedback/globalFeedback';
import Loader from 'src/shared/defaultLoader';

let config = require('src/config.json');

function App() {
  const [enableAutoQuery, setEnableAutoQuery] = React.useState(false);
  const getQuery = async () => await get('params/currentUser');
  const { refetch } = useQuery(['params/currentUser'], getQuery, {
    refetchOnWindowFocus: true,
    enabled: enableAutoQuery,
    onSuccess: (data) => {
      i18n.changeLanguage(data.lang);
    },
  });

  const getQueryGlobal = async () => await get('params/global');
  const { refetch: globalParamRefetch, isLoading } = useQuery(['params/global'], getQueryGlobal, {
    refetchOnWindowFocus: true,
    enabled: enableAutoQuery,
    onSuccess: (data) => {},
  });

  const getQueryHelp = async () => await get('params/info_view_details');
  const { refetch: globalHelpRefetch } = useQuery(['params/info'], getQueryHelp, {
    refetchOnWindowFocus: true,
    enabled: enableAutoQuery,
    onSuccess: (data) => {},
  });

  const { refetch: configRefetch } = useQuery(['local/config'], () => config, {
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: (data) => {},
  });

  React.useEffect(() => {
    AuthenticationService.currentUser.subscribe((user) => {
      if (user) {
        refetch();
        globalParamRefetch();
        globalHelpRefetch();
        configRefetch();
        setEnableAutoQuery(true);
      } else {
        setEnableAutoQuery(false);
      }
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <DisplayContextProvider>
        <Router>
          <Switch>
            <ProtectedRoute path="/admin" module={'ADMIN'} component={Admin} />
            <ProtectedRoute path="/user" module={'USER'} component={User} />
            <ProtectedRoute path="/display" module={'DISPLAY'} component={Display} />
            <Route exact path="/password-reset/:token" component={PasswordReset} />
            <Route exact path="/forgot_password" component={ForgottenPassword} />
            <Route exact path="/" component={Login} />
          </Switch>
        </Router>
      </DisplayContextProvider>
      <GlobalFeedback />
    </React.Fragment>
  );
}

export default App;
