import React from 'react';
export default function VTpIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="297mm" height="115mm" viewBox="0 0 297 115">
      <g transform="translate(715.57 539) scale(2.9595)">
        <path d="M-174.82-150.94l7.342 2.778 10.782-2.778-5.622-7.342-12.502.794zM-215.57-150.94l7.342 2.778 10.782-2.778-5.622-7.342-12.502.794z"></path>
        <path
          fill="#f0321a"
          stroke="#373737"
          strokeWidth="0.132"
          d="M-184.99-177.59h-45.62v25.46l3.289 3.715h11.877c.59-2.407 1.004-3.948 1.594-6.355.448-1.949 1.822-2.092 3.138-2.11h6.127c1.081.015 2.345.905 2.626 1.882l1.817 6.815 15.218.073z"
        ></path>
        <circle cx="-167.49" cy="-149.22" r="5.19" fill="#272727" strokeWidth="0.271"></circle>
        <circle cx="-167.49" cy="-149.22" r="3.295" fill="#a2a2a2" strokeWidth="0.172"></circle>
        <circle cx="-167.49" cy="-149.22" r="1.409" fill="#363636" strokeWidth="0.074"></circle>
        <path fill="#bebebe" stroke="#5e5e5e" strokeWidth="0.132" d="M-217.64 -180.58H-184.993V-177.595H-217.64z"></path>
        <path
          fill="#3e3e3e"
          d="M-153.1-157.86l-.196.647.754.452v4.716l-4.221 2.821-3.136.165c-.695-9.669-14.31-9.877-14.69 0h-7.877l-2.468-1.889.073-6.721.814-.07 10.535-4.11 13.552 1.34 6.86 2.649"
        ></path>
        <path
          fill="#f0321a"
          fillOpacity="0.968"
          stroke="#373737"
          strokeWidth="0.132"
          d="M-155.78-178.02l-27.428.025c-.689.002-.866.354-.865.95l.032 19.308 11.37-.001c.569-1.002 1.902-1.968 2.984-2.41 0 0 1.667-.042 3.505-.05 1.988.115 4.247.548 5.25 2.461 0 0 2.66-.147 7.838-.122.335-9.992.264-18.901-2.685-20.161z"
        ></path>
        <path
          fill="#1a1a1a"
          fillOpacity="0.968"
          d="M-170.1-176.85h15.104c.419-.015.451.06.594.354 1.21 2.605 1.255 6.506 1.406 10.34.08.718.039.76-.655.95l-.727.2c-.389.1-.613.101-.976.03l-14.593-2.827c-.304-.043-.403-.214-.379-.565l-.002-8.219c.004-.261.228-.263.228-.263z"
        ></path>
        <rect width="8.441" height="6.633" x="-179.7" y="-175.09" fill="#48486e" stroke="#201b1a" strokeWidth="0.232" rx="0.6"></rect>
        <path
          fill="#48486e"
          strokeWidth="0.175"
          d="M-168.6-175.09h12.603c.578 0 .977.47 1.044 1.044l.53 4.544c.066.575-.466 1.044-1.045 1.044H-168.6a1.042 1.042 0 01-1.044-1.044v-4.544c0-.578.466-1.044 1.044-1.044z"
        ></path>
        <path fill="#fff" fillOpacity="0.411" d="M-184.15-166.55c13.533 1.179 23.319 3.246 29.299 6.537-8.168-4.568-17.565-7.82-29.218-8.659z"></path>
        <g fill="#272727">
          <rect width="2.101" height="2.101" x="-180.42" y="-163.56" rx="0.2"></rect>
          <rect width="2.101" height="2.101" x="-166.67" y="-163.56" rx="0.2"></rect>
          <rect width="2.037" height="0.529" x="-215.14" y="-158.7" rx="0.194"></rect>
          <rect width="2.037" height="0.529" x="-202.97" y="-158.7" rx="0.194"></rect>
        </g>
        <g fill="none" stroke="#f1f2f5">
          <path strokeWidth="1.323" d="M-216.5-174.44v25.393"></path>
          <g strokeWidth="1.523">
            <path d="M-216.5-174.44v25.947M-199.04-174.44v26.178M-215.89-160.78h16.322"></path>
          </g>
        </g>
        <g fill="#fd3c2b" stroke="#3d3d3d">
          <path strokeWidth="0.13" d="M-198.37 -174.37H-185.966V-150.431H-198.37z"></path>
          <path strokeWidth="0.136" d="M-215.82 -174.37H-199.683V-161.244H-215.82z"></path>
          <path strokeWidth="0.132" d="M-229.8 -174.37H-217.05900000000003V-152.681H-229.8z"></path>
        </g>
        <path fill="none" stroke="#fff" strokeWidth="0.623" d="M-226.77-176.03h33.894"></path>
        <g fill="#f0321a" fillOpacity="0.968" stroke="#fff" strokeWidth="0.323">
          <path d="M-180.47-156.74h4.651M-180.47-153.57h4.651M-180.47-149.86h4.651M-159.37-154.62h3.66"></path>
        </g>
        <path fill="none" stroke="#201b1a" strokeWidth="0.723" d="M-166.15-175.09v6.633"></path>
        <rect width="1.951" height="4.597" x="-158.95" y="-173.43" fill="#1a1a1a" fillOpacity="0.968" rx="0.6"></rect>
        <g>
          <circle cx="-207.76" cy="-149.22" r="5.19" fill="#272727" strokeWidth="0.271"></circle>
          <circle cx="-207.76" cy="-149.22" r="3.295" fill="#a2a2a2" strokeWidth="0.172"></circle>
          <circle cx="-207.76" cy="-149.22" r="1.433" fill="#363636" strokeWidth="0.075"></circle>
        </g>
        <rect width="3.203" height="1.073" x="-160.55" y="-180.01" fill="#0056ff" fillOpacity="0.471" stroke="#003eff" strokeWidth="0.186" rx="0.16"></rect>
        <rect width="1.392" height="0.822" x="-159.71" y="-178.9" fill="#3d3d3d" rx="0.07"></rect>
      </g>
    </svg>
  );
}
