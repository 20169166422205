import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAllData, get } from 'src/api/api';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Loader from 'src/shared/defaultLoader';
import { DurationUtils } from 'src/services/durations';
import WorkforceEngagedSummary from 'src/shared/WorkforceEngagedSummary';
import Colors from 'src/styles/variables';
import { useUIContext } from '../../contexts/ui/ui.context';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

export default function AlarmReceipt() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('RECEIPTS'));
  }, []);

  const query = () => getAllData('alarm/receipt');
  const { data, error, isLoading, isError } = useQuery('alarm/receipt', query, {
    refetchInterval: 10000,
  });

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  if (isLoading || specializationIsLoading) {
    return <Loader />;
  }

  const alarmIds = [];
  let lastAlarmId = -1;
  for (let i = 0; i < data.length; i++) {
    if (data[i].alarm_id !== lastAlarmId) {
      alarmIds.push({ alarm_id: data[i].alarm_id, incident_type: data[i].incident_type, incident_description: data[i].incident_description });
      lastAlarmId = data[i].alarm_id;
    }
  }

  console.log(alarmIds);
  const ciSpec = specializations.find((spec) => spec.role_abbreviation === 'CI');
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <WorkforceEngagedSummary />
      </Grid>
      {alarmIds.map((alarm) => {
        return (
          <>
            {data.length > 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    color: 'text.secondary',
                    alignContent: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    textAlign: 'center',
                    flexDirection: 'column',
                    paddingBottom: '15',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      {`${alarm.incident_description}`}
                    </Typography>
                    <Box sx={{ m: 'auto', width: '70%', mb: '10px' }}>
                      <Divider />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {data.map((m, index) => {
                      const backgroundColor = m.incident_commander_id === m.member_id ? ciSpec.color : Colors.ALARM.darker;
                      const contrastTextColor = theme.palette.getContrastText(backgroundColor);
                      if (m.alarm_id == alarm.alarm_id) {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              bgcolor: backgroundColor,
                            }}
                          >
                            <TableCell sx={{ p: 1, color: contrastTextColor }}>
                              <a href={`tel://${m.phone}`}>{t('RANK_ABBR.' + m.rank_abbreviation)}</a>
                            </TableCell>
                            <TableCell sx={{ p: 1, color: contrastTextColor }} component="th" scope="row">
                              <a href={`tel://${m.phone}`}>{m.lastname + ' ' + m.firstname} </a>
                            </TableCell>
                            <TableCell sx={{ p: 1, color: contrastTextColor }} align="right">
                              <a href={`tel://${m.phone}`}>{DurationUtils.toReadableDuration(m.receipt_date)}</a>
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
