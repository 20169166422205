import React from 'react';
import Box from '@mui/material/Box';
import TableauMembre from '../tableau-membre/tableau-membre.component';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const TableauBloc = styled(TableauBlocBase)(({ theme }) => {
  return {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
export default TableauBloc;

const BlocName = styled('h1')({
  color: Colors.section,
  textAlign: 'center',
});

function TableauBlocBase({ title, displayAvailability, blocData, titleOnlyTotal = false, subtitle }) {
  const { t } = useTranslation();

  const nbDetails = blocData.reduce(
    (acc, currentData) => {
      if (currentData.status_code === 'AVAILABLE') {
        acc.available++;
      } else {
        acc.notAvailable++;
      }
      return acc;
    },
    { available: 0, notAvailable: 0 }
  );

  let titleAvailability = '';
  if (titleOnlyTotal) {
    titleAvailability = '' + (nbDetails.available + nbDetails.notAvailable);
  } else {
    titleAvailability = t('TITLE_TOTAL_AVAILABILITY', { avaialble: nbDetails.available, total: nbDetails.available + nbDetails.notAvailable });
  }

  return (
    <Box>
      {title ? (
        <BlocName>
          {title} - {titleAvailability}
        </BlocName>
      ) : null}
      {subtitle ? (
        <Box
          sx={{
            color: 'text.secondary',
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            paddingBottom: '15',
          }}
        >
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {`${subtitle}`}
            </Typography>
            <Box sx={{ m: 'auto', width: '70%', mb: '10px' }}>
              <Divider />
            </Box>
          </Box>
        </Box>
      ) : null}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {blocData.map((membre) => {
              if (
                displayAvailability === 'ALL' ||
                displayAvailability === undefined ||
                (displayAvailability === 'AVAILABLE_ONLY' && membre.status_code === 'AVAILABLE') ||
                (displayAvailability === 'UNAVAILABLE_ONLY' && (membre.status_code === null || membre.status_code === 'NOT_AVAILABLE'))
              )
                return <TableauMembre member={membre} key={membre.member_id} />;
              else return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
