import React from 'react';
import { getData, get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';

import MemberList from '../../components/memberList/memberList';

export default function GroupWorkForce({ groupId, noToggleButton }) {
  const getQueryFn = async () => await get(`group/${groupId}/members`);
  const { data, isLoading } = useQuery(['group-members', groupId], getQueryFn);
  if (isLoading) {
    return <Loader />;
  }

  return <MemberList data={data} noToggleButton forceAllView />;
}
