import axios from 'axios';
import { AuthenticationService } from 'src/services/auth';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  // baseURL: 'http://localhost:3333/',
});
// console.log(`backend : ${process.env.REACT_APP_API_SERVER}`);
api.interceptors.request.use((config) => {
  // const user = JSON.parse(localStorage.getItem('currentUser'));
  const user = AuthenticationService.currentUserValue;

  if (user && user.access_token) {
    const token = 'Bearer ' + user.access_token;
    config.headers.Authorization = token;
  }

  return config;
});

export default api;
export const baseUrl = `${process.env.REACT_APP_API_SERVER}`;
