import React from 'react';

import TableauBloc from '../tableau-bloc/tableau-bloc';
import { Utils } from 'src/services/utils';
import { useTranslation } from 'react-i18next';

export default function TableauReceipt({ title, groupId, nameSuffix, namePrefix, datas, incident }) {
  const { t } = useTranslation();

  if (namePrefix) {
    title = namePrefix + ' ' + title;
  }

  const subtitle = `${incident.incident_description || 'Nouvelle alarme'}`;

  if (nameSuffix) {
    title = title + ' ' + nameSuffix;
  }

  const members = [];
  for (const key in datas) {
    if (Object.hasOwnProperty.call(datas, key)) {
      const element = datas[key];
      for (const member of element.members) {
        if (member.incident_id == incident.incident_id) {
          members.push(member);
        }
      }
    }
  }

  if (members.length === 0) return null;

  return <TableauBloc title={title} subtitle={subtitle} displayAvailability={'ALL'} blocData={Utils.sortByRankAndName(members)} titleOnlyTotal />;
}
