import React from 'react';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import TableauGroup from 'src/modules/display/components/tableau-group/tableau-group.component';

export default function GroupAvailability({ groupId }) {
  const getQueryFn = async () => await get(`board/raw?group_id=${groupId}`);
  const { data, isLoading } = useQuery(['group-members-availability', groupId], getQueryFn);
  if (isLoading) {
    return <Loader />;
  }

  return <TableauGroup groupId={groupId} datas={{ group: { members: data } }} />;
}
