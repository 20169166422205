import moment from 'moment';
import 'moment/locale/fr';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
const _MS_PER_WEEK = 1000 * 60 * 60 * 24 * 7;

function getMonday(d) {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  let result = new Date(d.setDate(diff));

  return moment(result).startOf('day').toDate();
}

function getSunday(d) {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() + (7 - (day === 0 ? 7 : day)); // adjust when day is sunday
  let result = new Date(d.setDate(diff));

  return moment(result).endOf('day').toDate();
}

function addDays(baseDate, days) {
  const date = new Date(baseDate);
  date.setDate(date.getDate() + days);
  return date;
}

function addMinutes(baseDate, minutes) {
  return moment(baseDate).add(minutes, 'minutes').toDate();
}
function toLongDate(d) {
  moment.locale('fr');
  return moment(d).locale('fr').format('DD MMM yyyy');
}

function toStandardDate(d) {
  return moment(d).format('DD.MM.yyyy');
}

function toShortDateTime(d) {
  return moment(d).format('DD.MM HH:mm');
}

function toDateTime(d) {
  return moment(d).format('DD.MM.yyyy HH:mm');
}

function toFullDateTime(d) {
  return moment(d).format('DD.MM.yyyy HH:mm:ss');
}
function toTime(d) {
  return moment(d).format('HH:mm:ss');
}
function toShortTime(d) {
  return moment(d).format('HH:mm');
}
function toDate(d) {
  return moment(d).format('DD.MM.yyyy');
}

function getWeekNumber(d) {
  return moment(d).week();
}

function getYear(d) {
  return moment(d).year();
}

function getDayName(date) {
  switch (date.getDay()) {
    case 1:
      return 'MONDAY_ABBR';
    case 2:
      return 'TUESDAY_ABBR';
    case 3:
      return 'WEDNESDAY_ABBR';
    case 4:
      return 'THUSDAY_ABBR';
    case 5:
      return 'FRIDAY_ABBR';
    case 6:
      return 'SATURDAY_ABBR';
    case 0:
      return 'SUNDAY_ABBR';
    default:
      break;
  }
}
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const DateUtiles = {
  getMonday,
  toStandardDate,
  getSunday,
  addDays,
  addMinutes,
  toShortDateTime,
  getWeekNumber,
  getYear,
  getDayName,
  toLongDate,
  dateDiffInDays,
  toDateTime,
  toFullDateTime,
  toTime,
  toDate,
  toShortTime,
};
