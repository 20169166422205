import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function VehicleOnWayIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 150 150">
      <g stroke="#388bea" transform="translate(-116.44 913.45) scale(1.2664)">
        <g fill="#388bea">
          <path
            d="M285.81 266.03v36.483c91.16 9.032 145.41 40.674 145.41 64.427 0 27.993-75.088 67.024-197.36 67.024S36.52 394.933 36.52 366.94c0-23.93 54.996-55.856 147.32-64.635v-36.466c-96.133 9-183.83 43.747-183.83 101.1 0 67.247 120.49 103.54 233.86 103.54 113.37 0 233.88-36.293 233.88-103.54 0-56.972-86.541-91.623-181.92-100.91z"
            transform="translate(126.88 -684.58) scale(.16586)"
          ></path>
          <path
            d="M486.87 40.363a17.164 17.164 0 00-9.145-2.63c-2.551 0-5.098.558-7.472 1.704l-30.271 14.689a102.095 102.095 0 01-44.593 10.245 102.299 102.299 0 01-46.919-11.392 101.812 101.812 0 00-46.695-11.327 102.466 102.466 0 00-48.592 12.252V42.8c0-10.149-8.236-18.37-18.369-18.37S216.46 32.651 216.46 42.8v328.94c0 10.133 8.221 18.354 18.354 18.354s18.369-8.221 18.369-18.354V203.85a102.542 102.542 0 0148.607-12.268c15.996 0 32.007 3.76 46.68 11.326a102.239 102.239 0 0091.511 1.149l45.245-21.953a17.144 17.144 0 009.686-15.438V54.906a17.161 17.161 0 00-8.042-14.545z"
            transform="translate(126.88 -684.58) scale(.16586)"
          ></path>
        </g>
        <path
          fill="none"
          strokeDasharray="12.4524, 12.4524"
          strokeWidth="6.226"
          d="M130.59-625.25l-23.142-2.817c-24.975-12.545-8.353-21.37 27.433-40.636 35.786-19.267-4.323-28.444-16.635-33.49"
        ></path>
        <circle cx="107.39" cy="-703.64" r="11.344" fill="#fff" strokeDashoffset="6.86" strokeWidth="5.493"></circle>
      </g>
    </svg>
  );
}
