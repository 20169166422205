import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function VehicleOutOfOrderIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 150 150">
      <g fill="#ffaf00" display="none" transform="translate(2.994 2.993) scale(.29099)">
        <path d="M285.81 266.03v36.483c91.16 9.032 145.41 40.674 145.41 64.427 0 27.993-75.088 67.024-197.36 67.024-122.272 0-197.34-39.031-197.34-67.024 0-23.93 54.996-55.856 147.32-64.635v-36.466c-96.133 9-183.83 43.747-183.83 101.1 0 67.247 120.49 103.54 233.86 103.54 113.37 0 233.88-36.293 233.88-103.54 0-56.972-86.541-91.623-181.92-100.91z"></path>
        <path d="M486.87 40.363a17.164 17.164 0 00-9.145-2.63c-2.551 0-5.098.558-7.472 1.704l-30.271 14.689a102.095 102.095 0 01-44.593 10.245 102.299 102.299 0 01-46.919-11.392 101.812 101.812 0 00-46.695-11.327 102.466 102.466 0 00-48.592 12.252V42.8c0-10.149-8.236-18.37-18.369-18.37S216.46 32.651 216.46 42.8v328.94c0 10.133 8.221 18.354 18.354 18.354s18.369-8.221 18.369-18.354V203.85a102.542 102.542 0 0148.607-12.268c15.996 0 32.007 3.76 46.68 11.326a102.239 102.239 0 0091.511 1.149l45.245-21.953a17.144 17.144 0 009.686-15.438V54.906a17.161 17.161 0 00-8.042-14.545z"></path>
      </g>
      <g display="none" transform="matrix(83.57188 0 0 94.27553 -13822.925 -1896.692)">
        <path
          fill="#720d94"
          fillOpacity="1"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0"
          d="M165.703 20.276c.034 0 .06.05.04.075-.055.067-.106.131-.141.21a.777.777 0 00.086.78c.029.038.09.076.045.12-.008.008-.018.02-.03.02-.022 0-.043-.035-.055-.05a.858.858 0 01-.117-.18.881.881 0 01.015-.775.891.891 0 01.105-.155c.012-.014.031-.045.052-.045m1.221-.006a.873.873 0 010 1.216h-.005c-.015-.014-.05-.037-.05-.06.001-.027.055-.073.072-.095a.781.781 0 00.022-.874c-.045-.069-.14-.127-.039-.187m-.196.945h-.306c-.026.16-.214.143-.222 0h-.306c.024-.089.096-.17.11-.261.022-.147-.037-.31.036-.447.105-.2.395-.212.523-.03.1.14.032.319.056.477.013.09.085.172.11.261m-.905.166a.45.45 0 01-.113-.14.701.701 0 01.056-.78c.01-.014.035-.053.057-.05.036.004.057.048.037.075-.118.156-.188.33-.145.528a.625.625 0 00.09.211c.014.023.067.069.067.096 0 .022-.035.046-.05.06m.98 0c-.016-.012-.054-.037-.054-.06 0-.024.041-.057.054-.076a.604.604 0 00-.006-.714c-.028-.037-.08-.092-.003-.119.025-.009.049.034.061.05a.701.701 0 01-.051.92m-.779-.252h.573c-.016-.056-.06-.108-.065-.166-.013-.134.04-.313-.035-.432-.09-.142-.317-.129-.388.025-.055.118-.008.28-.02.407-.005.058-.05.11-.065.166z"
          display="none"
        ></path>
      </g>
      <g
        fill="none"
        fillOpacity="1"
        stroke="#f6f6f6"
        strokeLinecap="butt"
        strokeOpacity="1"
        strokeWidth="0.509"
        display="none"
        transform="matrix(34.39282 0 0 34.39282 -5111.133 -939.851)"
      >
        <path fillRule="evenodd" strokeLinejoin="round" d="M151.608 28.368a1.508 1.508 0 11-1.506.002"></path>
        <path strokeDasharray="none" strokeLinejoin="miter" d="M150.854 29.219v-1.747"></path>
      </g>
      <g stroke="#c3c3c3" strokeDasharray="none" strokeOpacity="1" strokeWidth="4" transform="matrix(3.60435 0 0 3.60435 -1053.619 -391.4)">
        <circle cx="313.002" cy="129.357" r="15.618" fill="none" fillOpacity="1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></circle>
        <path fill="#f0f" strokeLinecap="butt" strokeLinejoin="miter" d="M301.947 118.325l22.034 22.141"></path>
      </g>
    </svg>
  );
}
