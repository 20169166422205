import React, { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import Colors from 'src/styles/variables';
import Button from '../../components/button/button';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/shared/sharedModal';
import { put, get } from 'src/api/api';
import { useQuery, useQueryClient } from 'react-query';
import { Colors } from 'src/styles/variables';

export default function CloseIncidentButton() {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { refetch } = useQuery(['currentUserStatus'], getQueryFn, {
    manual: true,
  });

  const alarmClickHandler = async () => {
    setOpen(false);
    await put('alarm/close');
    queryClient.invalidateQueries('workForce');
    refetch();
  };

  return (
    <>
      <Button icon={HighlightOffIcon} iconColor={Colors.button_default_icon} bgColor={Colors.button_default} onClick={() => setOpen(true)}>
        {t('CLOSE_INCIDENT')}
      </Button>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        Message={t('CONFIRM_CLOSE_INCIDENT')}
        onConfirm={() => alarmClickHandler()}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
    </>
  );
}
