import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function MapsList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'map_id', headerName: 'ID', hide: true },
    {
      field: 'map_name',
      headerName: t('NAME'),
      width: '150',
      flex: 1,
      editable: true,
    },
    {
      field: 'map_order',
      headerName: t('SORT_ORDER'),
      width: '150',
      flex: 1,
      editable: true,
    },
  ];

  return <GenericList objectName={'maps'} columns={columns} rowId={'map_id'} />;
}
