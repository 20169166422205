import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function DirectoryContactList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'phone_directory_entry_id', headerName: 'ID', hide: true },
    {
      field: 'phone_directory_entry_name',
      headerName: t('FIRST_NAME'),
      width: '*',
      flex: 1,
      editable: true,
    },
    {
      field: 'last_name',
      headerName: t('LAST_NAME'),
      width: '*',
      flex: 1,
      editable: true,
    },
    {
      field: 'phone_directory_entry_number',
      headerName: t('PHONE'),
      width: '*',
      flex: 1,
      editable: true,
    },
    {
      field: 'email',
      headerName: t('EMAIL'),
      width: '*',
      flex: 1,
      editable: true,
    },
  ];

  return <GenericList objectName={'directory/contact'} columns={columns} rowId={'phone_directory_entry_id'} />;
}
