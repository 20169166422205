import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import api from 'src/api/axios';
import frLocale from 'date-fns/locale/fr-CH';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Colors } from 'src/styles/variables';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import StatusSelectorButton from '../../components/statusSelectorButton/statusSelectorButton';

export default function UserAvailabilities() {
  const { t } = useTranslation();
  let { id } = useParams();

  const defaultToDate = new Date();
  defaultToDate.setHours(defaultToDate.getHours() + 2);

  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(defaultToDate);

  const getQueryFn = async ({ queryKey }) => await getData('user', { queryKey });

  const { data: memberData, error, isLoading, isError } = useQuery(['user', { id }], getQueryFn);

  const history = useHistory();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  const statusClickHandler = async (status) => {
    const data = {
      from: fromDate,
      to: toDate,
      status: status,
      member_id: id,
    };
    // console.log(data);
    await api.post('/availability', data);
    // console.log(result);
    history.goBack();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ p: 3, mb: 1 }}>
        <Typography variant="h6" gutterBottom>
          {t('NEW_AVAILABILITY_FOR_MEMBER', { member_name: memberData.firstname + ' ' + memberData.lastname })}
        </Typography>
      </Paper>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t('DETAILS')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <Stack spacing={3}>
                <DateTimePicker
                  label={t('FROM')}
                  ampm={false}
                  value={fromDate}
                  inputFormat="dd.MM.yyyy HH:mm"
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <Stack spacing={3}>
                <DateTimePicker
                  inputFormat="dd.MM.yyyy HH:mm"
                  label={t('TO')}
                  ampm={false}
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StatusSelectorButton
              icon={EventAvailableIcon}
              iconColor={Colors.AVAILABLE.darker}
              bgColor={Colors.AVAILABLE.light}
              textColor={Colors.AVAILABLE.darker}
              onClick={() => statusClickHandler('AVAILABLE')}
            >
              {t('AVAILABLE')}
            </StatusSelectorButton>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StatusSelectorButton
              icon={DoNotDisturbOnIcon}
              iconColor={Colors.NOT_AVAILABLE.darker}
              bgColor={Colors.NOT_AVAILABLE.light}
              textColor={Colors.NOT_AVAILABLE.darker}
              onClick={() => statusClickHandler('NOT_AVAILABLE')}
            >
              {t('NOT_AVAILABLE')}
            </StatusSelectorButton>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="error" onClick={() => history.goBack()}>
                  {t('CANCEL')}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
