import React, { useState, useEffect } from 'react';
import Agenda from '../../components/agenda/agenda';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
// import Button from '../../components/button/button';
import { DateUtiles } from 'src/services/dateUtiles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Colors from 'src/styles/variables';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import AvailabilityList from '../../components/availabilityList/availabilityList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useUIContext } from '../../contexts/ui/ui.context';
import WeekSelector from 'src/shared/weekSelector';
import AvailabilityMenuButton from './availabilityMenuButton';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Calendar({ memberId = null }) {
  const [agendaType, setAgendaType] = React.useState('AVAILABILITY');

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setAgendaType(newType);
    }
  };

  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();

  const getQueryFn = async ({ queryKey }) => await getData('user', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['user', { id: memberId }], getQueryFn);

  useEffect(() => {
    let rank = '';
    if (data?.rank_abbreviation) {
      rank = t('RANK_ABBR.' + data.rank_abbreviation);
    }
    setAppBarTitle(`${rank} ${data?.firstname ?? ''} ${data?.lastname ?? ''}`);
  }, [data]);

  let { url } = useRouteMatch();
  const history = useHistory();

  const [baseDate, setBaseDate] = useState(new Date());
  const [dateTitle, setDateTitle] = useState('');

  useEffect(() => {
    const monday = DateUtiles.getMonday(baseDate);
    const sunday = DateUtiles.getSunday(baseDate);
    const dateTitle = `${DateUtiles.toStandardDate(monday)} - ${DateUtiles.toStandardDate(sunday)}`;
    setDateTitle(dateTitle);
  }, [baseDate]);

  // const updateBaseDate = (delta) => {
  //   setBaseDate(DateUtiles.addDays(baseDate, delta));
  // };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WeekSelector baseDate={baseDate} onWeekChange={(newDate) => setBaseDate(newDate)} />
        </Grid>

        <Grid item xs={12}>
          <ToggleButtonGroup color="primary" value={agendaType} exclusive fullWidth onChange={handleChange}>
            <ToggleButton value="AVAILABILITY">{t('AVAILABILITY')}</ToggleButton>
            <ToggleButton value="FUNCTION">{t('FUNCTION')}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Agenda baseDate={baseDate} type={agendaType} memberId={memberId} />
        </Grid>

        {/* <Grid item xs={12}>
          <Availability />
        </Grid> */}
        {agendaType === 'AVAILABILITY' && memberId === null ? (
          <React.Fragment>
            <Grid item xs={9}>
              {/* <Button icon={AddCircleIcon} iconColor={Colors.button_default_icon} bgColor={Colors.button_default} onClick={() => history.push(`availability`)}>
                {t('NEW_AVAILABILITY')}
              </Button> */}
              <Button fullWidth variant="contained" startIcon={<AddCircleIcon />} size="large" color="neutral" onClick={() => history.push(`availability`)}>
                {t('NEW_AVAILABILITY')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <AvailabilityMenuButton baseDate={baseDate} />
            </Grid>
          </React.Fragment>
        ) : null}

        <Grid item xs={12}>
          <AvailabilityList baseDate={baseDate} type={agendaType} memberId={memberId} />
        </Grid>
      </Grid>
    </>
  );
}
