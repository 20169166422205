import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Loader from 'src/shared/defaultLoader';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getData } from 'src/api/api';
import AccessForm from './accessForm';

export default function Access() {
  let { id } = useParams();

  const getQueryFn = async ({ queryKey }) => await getData('auth/user/access', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['user-access', { id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={3}>
      {Object.keys(data).map((key) => {
        return <AccessForm key={key} id={id} type={key} access={data[key]} />;
      })}
    </Grid>
  );
}
