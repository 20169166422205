import React from 'react';
import Boards from 'src/modules/display/pages/boards/boards';
import { useUIContext } from '../../contexts/ui/ui.context';
import { useTranslation } from 'react-i18next';
export default function UserBoard({ noLogo }) {
  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('BOARD_NAME'));
  }, []);
  // return <Boards breakPoints={{ xs: 1 }} />; //for mui massonry
  return <Boards noLogo breakPoints={{ default: 1 }} />; // For standard massonry
}
