import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useStore from './globalFeedback.store';

export default function GlobalFeedback() {
  const feedback = useStore((state) => state.feedback);
  const setFeedback = useStore((state) => state.setFeedback);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={feedback.msg !== ''}
      autoHideDuration={3000}
      onClose={() => {
        setFeedback({ msg: '', severity: '' });
      }}
    >
      <Alert
        onClose={() => {
          setFeedback({ msg: '', severity: '' });
        }}
        severity={feedback.severity || 'success'}
        sx={{ width: '100%' }}
      >
        {feedback.msg}
      </Alert>
    </Snackbar>
  );
}
