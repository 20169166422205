import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function VehicleOnSiteIcon(props) {
  return (
    <svg {...props} width="40px" height="40px" version="1.1" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.29099 0 0 .29099 2.9939 2.9934)" fill="#ffaf00">
        <path d="m285.81 266.03v36.483c91.16 9.032 145.41 40.674 145.41 64.427 0 27.993-75.088 67.024-197.36 67.024s-197.34-39.031-197.34-67.024c0-23.93 54.996-55.856 147.32-64.635v-36.466c-96.133 9-183.83 43.747-183.83 101.1 0 67.247 120.49 103.54 233.86 103.54 113.37 0 233.88-36.293 233.88-103.54 0-56.972-86.541-91.623-181.92-100.91z" />
        <path d="m486.87 40.363c-2.789-1.752-5.959-2.63-9.145-2.63-2.551 0-5.098.558-7.472 1.704l-30.271 14.689c-14.084 6.836-29.346 10.245-44.593 10.245-16.123 0-32.214-3.808-46.919-11.392-14.673-7.568-30.699-11.327-46.695-11.327-16.728 0-33.441 4.095-48.592 12.252v-11.104c0-10.149-8.236-18.37-18.369-18.37s-18.354 8.221-18.354 18.37v328.94c0 10.133 8.221 18.354 18.354 18.354s18.369-8.221 18.369-18.354v-167.89c15.15-8.157 31.88-12.268 48.607-12.268 15.996 0 32.007 3.76 46.68 11.326 14.705 7.584 30.796 11.393 46.902 11.393 15.247 0 30.51-3.41 44.609-10.244l45.245-21.953c5.927-2.868 9.686-8.859 9.686-15.438v-111.76c.003-5.927-3.056-11.423-8.042-14.545z" />
      </g>
    </svg>
  );
}
