import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';

const ReactHookFormSelect = ({ name, label, control, defaultValue, children, id, required, disabled = false, ...props }) => {
  const [fieldValue, setFieldValue] = React.useState(defaultValue ?? '');
  const labelId = `${name}-label`;
  return (
    <Controller
      name={name}
      id={id}
      control={control}
      defaultValue={fieldValue}
      render={({ field }) => (
        <FormControl required={required} variant="standard" fullWidth={true}>
          <InputLabel>{label}</InputLabel>
          <Select {...field} labelId={labelId} id={id} label={label} onChange={field.onChange} disabled={disabled}>
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
};
export default ReactHookFormSelect;
