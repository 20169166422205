import React from 'react';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

export default function PopulatedSelect({ value, control, endoint, name, id, label, withBlankOption, displayAttribute }) {
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = React.useState(value ?? '');
  const getSpecializationQueryFn = async () => await getAllData(endoint);
  const { data, isLoading } = useQuery(`${endoint}s`, getSpecializationQueryFn);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Controller
      name={name}
      id={id}
      control={control}
      defaultValue={fieldValue}
      render={({ field }) => (
        <FormControl fullWidth variant="standard">
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
          <Select {...field} labelId={`${id}-label`} id={id} label={label} onChange={field.onChange}>
            {withBlankOption ? <MenuItem value={''}>&nbsp;</MenuItem> : null}

            {data.map((e) => {
              return (
                <MenuItem key={e[id]} value={e[id]}>
                  {t(e[displayAttribute])}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
}
