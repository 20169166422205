import React from 'react';
import Box from '@mui/material/Box';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import { styled } from '@mui/system';
import { Colors } from 'src/styles/variables';
import Loader from 'src/shared/defaultLoader';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { TodayTwoTone } from '@mui/icons-material';
import MaterialButton from './materialButton';
import Grid from '@mui/material/Grid';
import VehicleButton from './vehicleButton';

const BlocName = styled('h1')({
  color: Colors.section,
  textAlign: 'center',
});
export default function TableauVehicle({ title }) {
  const { t } = useTranslation();
  const isIC = false;
  const groupQuery = async () => await getAllData('vehicle/withState');
  const { data, isLoading: vehicleIsLoading } = useQuery('vehicle/withState', groupQuery, { staleTime: 1000, refetchInterval: 10000 });

  const materialQuery = async () => await getAllData('material/withState');
  const { data: materialData, isLoading: materialsIsLoading } = useQuery('material/withState', materialQuery, { staleTime: 1000, refetchInterval: 10000 });

  if (vehicleIsLoading || materialsIsLoading) {
    return <Loader />;
  }

  const total_engaged_vhc = data.reduce((previousValue, vehicle) => {
    if (vehicle.vehicle_status && vehicle.incident_id !== null) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_not_available_vhc = data.reduce((previousValue, vehicle) => {
    if (vehicle.vehicle_status && vehicle.vehicle_status !== 'TRAINING' && vehicle.incident_id === null) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_training_vhc = data.reduce((previousValue, vehicle) => {
    if (vehicle.vehicle_status === 'TRAINING') {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_available_vhc = data.reduce((previousValue, vehicle) => {
    if (!vehicle.vehicle_status) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  return (
    <Box>
      {title ? <BlocName>{title}</BlocName> : null}
      <React.Fragment>
        {total_engaged_vhc ? (
          <>
            <Box
              sx={{
                color: 'text.secondary',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column',
                paddingBottom: '0px',
                paddingTop: '0px',
              }}
            >
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  {t('VEHICLES_LINKED')}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={1}>
              {data.map((vehicle) => {
                if (vehicle.vehicle_status && vehicle.incident_id !== null) {
                  return (
                    <React.Fragment key={vehicle.vehicle_id}>
                      <Grid item xs={12}>
                        <VehicleButton vehicle={vehicle} />
                      </Grid>
                      {materialData.map((material) => {
                        if (material.vehicle_id === vehicle.vehicle_id) {
                          return (
                            <React.Fragment key={material.material_id}>
                              <Grid item xs={3}></Grid>
                              <Grid item xs={9}>
                                {/* {material.material_name} */}
                                <MaterialButton size="small" material={material} />
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      })}
                    </React.Fragment>
                  );
                }
              })}
            </Grid>
          </>
        ) : null}

        {total_available_vhc ? (
          <>
            <Box
              sx={{
                color: 'text.secondary',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column',
                paddingBottom: '0px',
                paddingTop: '15px',
              }}
            >
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  {t('VEHICLES_AVAILABLE')}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={1}>
              {data.map((vehicle) => {
                if (!vehicle.vehicle_status) {
                  return (
                    <React.Fragment key={vehicle.vehicle_id}>
                      <Grid item xs={isIC ? 10 : 12}>
                        <VehicleButton vehicle={vehicle} />
                      </Grid>
                      {materialData.map((material) => {
                        if (material.vehicle_id === vehicle.vehicle_id) {
                          return (
                            <React.Fragment key={material.material_id}>
                              <Grid item xs={3}></Grid>
                              <Grid item xs={9}>
                                {/* {material.material_name} */}
                                <MaterialButton size="small" material={material} />
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      })}
                    </React.Fragment>
                  );
                }
              })}
            </Grid>
          </>
        ) : null}

        {total_training_vhc ? (
          <>
            <Box
              sx={{
                color: 'text.secondary',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column',
                paddingBottom: '0px',
                paddingTop: '15px',
              }}
            >
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  {t('VEHICLES_TRAINNING')}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={1}>
              {data.map((vehicle) => {
                if (vehicle.vehicle_status === 'TRAINING') {
                  return (
                    <React.Fragment key={vehicle.vehicle_id}>
                      <Grid item xs={isIC ? 10 : 12}>
                        <VehicleButton vehicle={vehicle} />
                      </Grid>
                      {materialData.map((material) => {
                        if (material.vehicle_id === vehicle.vehicle_id) {
                          return (
                            <React.Fragment key={material.material_id}>
                              <Grid item xs={3}></Grid>
                              <Grid item xs={9}>
                                {/* {material.material_name} */}
                                <MaterialButton size="small" material={material} />
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      })}
                    </React.Fragment>
                  );
                }
              })}
            </Grid>
          </>
        ) : null}

        {total_not_available_vhc ? (
          <>
            <Box
              sx={{
                color: 'text.secondary',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                flexDirection: 'column',
                paddingBottom: '0px',
                paddingTop: '15px',
              }}
            >
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  {t('VEHICLES_NOT_AVAILABLE')}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={1}>
              {data.map((vehicle) => {
                if (vehicle.vehicle_status && vehicle.incident_id === null && vehicle.vehicle_status != 'TRAINING') {
                  return (
                    <Grid key={vehicle.vehicle_id} item xs={12}>
                      <VehicleButton vehicle={vehicle} />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </>
        ) : null}
      </React.Fragment>
    </Box>
  );
}
