import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import { LINK_TYPES } from 'src/constants/constants';

export default function Link() {
  const { register, watch, handleSubmit, control } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('link', { queryKey });

  const [linkIsMandatory, setLinkIsMandatory] = React.useState(true);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'link_type') {
        setLinkIsMandatory(value.link_type !== 'SEPARATOR');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { data, error, isLoading, isError } = useQuery(['link', { id }], getQueryFn, {
    onSuccess: (data) => {
      setLinkIsMandatory(data.link_type !== 'SEPARATOR');
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'link'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <ReactHookFormSelect
          register={register}
          id="link_type"
          name="link_type"
          label={t('LINK_TYPE')}
          control={control}
          defaultValue={data.link_type}
          variant="standard"
        >
          {LINK_TYPES.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('link_name')}
            required
            autoComplete="off"
            id="link_name"
            name="link_name"
            label={t('LINK_NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.link_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('link_order')}
            required
            id="link_order"
            name="link_order"
            label={t('LINK_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data?.link_order ?? 0}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('link_min_rank')}
            required
            id="link_min_rank"
            name="link_min_rank"
            label={t('LINK_MIN_RANK')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.link_min_rank || 0}
          />
        </FormControl>
      </Grid>

      {linkIsMandatory ? (
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('link_url')}
              required={linkIsMandatory}
              autoComplete="off"
              id="link_url"
              name="link_url"
              label={t('LINK')}
              fullWidth
              variant="standard"
              defaultValue={data.link_url}
            />
          </FormControl>
        </Grid>
      ) : null}
    </GenericCRUD>
  );
}
