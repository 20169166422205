import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import { DateUtiles } from 'src/services/dateUtiles';
import { DurationUtils } from 'src/services/durations';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { AuthenticationService } from 'src/services/auth';
import { deleteData } from 'src/api/api';
import { useMutation, useQueryClient } from 'react-query';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import AlertDialog from 'src/shared/sharedModal';

export default function IncidentStaffLine({ staff, allOpen }) {
  const { t } = useTranslation();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [curentLine, setCurentLine] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);
  const queryClient = useQueryClient();
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const deleteQueryFn = async ({ id, ...data }) => {
    await deleteData(`incident/remove-staff`, { id: data.alarm_receipt_id, ...data });
  };

  const { mutateAsync: mutateAsyncDelete } = useMutation(deleteQueryFn, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['incidentStaff']);
    },
  });

  async function deleteCurrentRecord(data) {
    const res = await mutateAsyncDelete({ ...data });
    if (res) {
      if (res.isError) {
        setFeedback({ msg: t(res.message), severity: 'error' });
      }
    }
  }

  const handleDeleteClick = () => {
    deleteCurrentRecord(curentLine);
  };
  React.useEffect(() => {
    setOpen(allOpen);
  }, [allOpen]);

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ pb: '5px', pt: '5px' }}>
        <ListItemText primary={`${staff[0].rank_abbreviation} ${staff[0].lastname} ${staff[0].firstname}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {staff?.map((line) => {
            return (
              <ListItem
                key={line.alarm_receipt_id}
                sx={{ pl: 4 }}
                secondaryAction={
                  isIC ? (
                    <IconButton
                      onClick={() => {
                        setCurentLine(line);
                        setDeleteOpen(true);
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null
                }
              >
                <ListItemText
                  sx={{ p: 0, m: 0 }}
                  primary={`${DurationUtils.toReadableDurationBetweenTwoDate(line.receipt_date, line?.end_date ?? '')}`}
                  secondary={`${DateUtiles.toDateTime(line.receipt_date)} - ${line.end_date ? DateUtiles.toDateTime(line.end_date) : t('IN_PROGRESS')} `}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <AlertDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        Message={t('Êtes-vous sûr de vouloir supprimer cet enregistrement?')}
        onConfirm={() => handleDeleteClick()}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
    </>
  );
}
