import React from 'react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import useStoreQuickEntry from '../quickEntry.store';
import { createData } from 'src/api/api';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ColorButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'bgColor' })(({ theme, bgColor }) => {
  return {
    color: bgColor && bgColor != '#f6f6f6' ? theme.palette.getContrastText(bgColor) : null,
    backgroundColor: bgColor,
  };
});

export default function QuickEntryButton({ entry, interventionId, setCurrentFolder }) {
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];

  const { t } = useTranslation();

  const setLogEntry = useStoreQuickEntry((state) => state.setLogEntry);
  setLogEntry(null);
  let startIcon = <FolderOpenIcon />;
  if (entry.item_type === 'ACTION') {
    if (entry.is_editable) {
      startIcon = <ChatBubbleIcon />;
    } else {
      startIcon = <QuickreplyIcon />;
    }
  }
  return (
    <ColorButton
      fullWidth
      variant="contained"
      startIcon={startIcon}
      size="large"
      color="neutral"
      // bgcolor={curentFolder.parent?.color_code ?? null}
      bgColor={entry.color_code}
      sx={{ height: '100%' }}
      onClick={async () => {
        switch (entry.item_type) {
          case 'ACTION':
            if (entry.is_editable) {
              setLogEntry(entry);
              history.push(`/${module}/intervention/${interventionId}/log/entry/0`);
            } else {
              await createData('incident-logs', {
                subject: entry.subject,
                comment: entry.comment,
                incident_id: interventionId,
                event_date: Date(),
              });
              history.push(`/${module}/intervention/${interventionId}/log`);
            }

            break;
          default:
            setCurrentFolder(entry);
            break;
        }
      }}
    >
      {t(entry.name)}
    </ColorButton>
  );
}
