import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { get } from 'src/api/api';
import TableChartIcon from '@mui/icons-material/TableChart';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Grid from '@mui/material/Grid';
// import Button from '../../components/button/button';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useUIContext } from '../../contexts/ui/ui.context';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MapIcon from '@mui/icons-material/Map';
import { useQueryClient } from 'react-query';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningIcon from '@mui/icons-material/Warning';
import { AuthenticationService } from 'src/services/auth';

export default function Apps() {
  const { t } = useTranslation();

  const history = useHistory();
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);
  //const isSquadLeaderOrHigher = AuthenticationService.checkUserMaxAccessLevel() > 80;

  const { setAppBarTitle } = useUIContext();
  useEffect(() => {
    setAppBarTitle('Alarmo');
  }, []);

  return (
    <Grid container spacing={1}>
      {/* <Grid item xs={12} sx={{ color: 'text.secondary', textAlign: 'center' }}>
        <Typography variant="h6">{t('OTHER_FUNCTIONS')}</Typography>
      </Grid> */}

      <Grid item xs={12}>
        <Button
          fullWidth
          disabled={!globalParam?.google_map_api}
          variant="contained"
          startIcon={<MapIcon />}
          size="large"
          color="neutral"
          onClick={() => history.push(`/user/maps`)}
        >
          {t('MAPS')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<CircleNotificationsIcon />}
          size="large"
          color="neutral"
          onClick={() => history.push(`/user/receipts`)}
        >
          {t('RECEIPTS')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button fullWidth variant="contained" startIcon={<LocalShippingIcon />} size="large" color="neutral" onClick={() => history.push(`/user/apparatus`)}>
          {t('VEHICLES_AND_MATERIALS')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button fullWidth variant="contained" startIcon={<LinkIcon />} size="large" color="neutral" onClick={() => history.push(`/user/links`)}>
          {t('LINKS')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button fullWidth variant="contained" startIcon={<EventNoteIcon />} size="large" color="neutral" onClick={() => history.push(`/user/planning`)}>
          {t('DUTY')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button fullWidth variant="contained" startIcon={<ListAltIcon />} size="large" color="neutral" onClick={() => history.push(`/user/member`)}>
          {t('MEMBERS_LIST')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button fullWidth variant="contained" startIcon={<ContactPhoneIcon />} size="large" color="neutral" onClick={() => history.push(`/user/phone_books`)}>
          {t('PHONE_BOOKS')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button fullWidth variant="contained" startIcon={<TableChartIcon />} size="large" color="neutral" onClick={() => history.push(`/user/board`)}>
          {t('DISPLAY')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<CalendarViewWeekIcon />}
          size="large"
          color="neutral"
          onClick={() => history.push(`/user/all-planning`)}
        >
          {t('OPERATIONAL_STATE')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button fullWidth variant="contained" startIcon={<WarningIcon />} size="large" color="neutral" onClick={() => history.push(`/user/interventions`)}>
          {t('INCIDENTS')}
        </Button>
      </Grid>
    </Grid>
  );
}
