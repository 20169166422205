import React from 'react';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import TableauSpecialization from 'src/modules/display/components/tableau-specialization/tableau-specialization.component';

export default function SpecializationAvailability({ specId }) {
  const getQueryFn = async () => await get(`board/raw?specialization_id=${specId}`);
  const { data, isLoading } = useQuery(['specialization-members-availability', specId], getQueryFn);
  if (isLoading) {
    return <Loader />;
  }

  return <TableauSpecialization specializationId={specId} datas={{ spec: { members: data } }} />;
}
