import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import i18n from 'src/i18n/i18n';
import { AuthenticationService } from 'src/services/auth';
import { useHistory } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

import { useTranslation } from 'react-i18next';

export default function Topbar() {
  const history = useHistory();

  const [anchorLanguageMenuEl, setAnchorLanguageEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguageMenuOpen = Boolean(anchorLanguageMenuEl);

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLanguageMenuClose = () => {
    setAnchorLanguageEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorLanguageEl(event.currentTarget);
  };

  const handleLogout = () => {
    AuthenticationService.logout();
    history.push('/');
  };

  const languageMenuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <LanguageIcon />
        </IconButton>
        <p>{t('LANGUAGE')}</p>
      </MenuItem>

      <MenuItem onClick={handleLogout}>
        <IconButton size="large" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p>{t('LOGOUT')}</p>
      </MenuItem>
    </Menu>
  );

  const renderLanguageMenu = (
    <Menu
      anchorEl={anchorLanguageMenuEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={languageMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem
        onClick={() => {
          changeLanguage('fr');
          handleLanguageMenuClose();
        }}
      >
        Français
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeLanguage('de');
          handleLanguageMenuClose();
        }}
      >
        Deutsch
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeLanguage('en');
          handleLanguageMenuClose();
        }}
      >
        English
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ display: { sm: 'block' } }}>
            Alarmo
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" aria-label="change language" color="inherit" onClick={handleLanguageMenuOpen}>
              <LanguageIcon />
            </IconButton>

            <IconButton size="large" color="inherit" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderLanguageMenu}
    </>
  );
}
