import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAllData, get } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import { useUIContext } from '../../contexts/ui/ui.context';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

export default function DirectoryContact() {
  let { groupId, directoryId } = useParams();
  const { setAppBarTitle } = useUIContext();
  const { t } = useTranslation();

  const query = () => getAllData(`directory/contact/${groupId}/${directoryId}`);
  const { data, isLoading } = useQuery(`directory/contact/${groupId}/${directoryId}`, query);

  const getQueryFn = async () => await get(`directory/${directoryId}`);
  const { isLoading: groupIsLoading } = useQuery(['directory/', directoryId], getQueryFn, {
    onSuccess: (data) => {
      setAppBarTitle(`${t('PHONE_BOOK')}: ${data.phone_directory_name}`);
    },
  });

  if (isLoading || groupIsLoading) {
    return <Loader />;
  }
  // console.log(data);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size={'medium'}>
            <TableBody>
              {data.map((contact) => {
                return <Line key={contact.phone_directory_entry_id} contact={contact} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

const LineV = function ({ contact }) {
  const { t } = useTranslation();

  return (
    <TableRow
      hover
      sx={{ height: '54px' }}
      // selected={selected}
      onClick={(event) => {
        window.location = 'tel:' + contact.phone_directory_entry_number;
      }}
    >
      <TableCell>
        {' '}
        {`${contact.civilities ? t(contact.civilities) + ' ' : ''}${contact.phone_directory_entry_name}${contact.last_name ? ' ' + contact.last_name : ''}`}
      </TableCell>
      <TableCell> {contact.function_name}</TableCell>
      <TableCell> {contact.phone_directory_entry_number}</TableCell>
    </TableRow>
  );
};
const Line = React.memo(LineV);
