import Colors from 'src/styles/variables';

export const LINK_TYPES = [
  { code: 'MAP', name: 'MAP' },
  { code: 'LINK', name: 'LINK' },
  { code: 'SEPARATOR', name: 'SEPARATOR' },
];

export const INCIDENT_TYPE = [
  { code: 'MAP', name: 'MAP' },
  { code: 'LINK', name: 'LINK' },
];

export const INCIDENT_STATUS = [
  { code: 'IN_PROGRESS', name: 'IN_PROGRESS' },
  { code: 'CLOSED', name: 'CLOSED' },
];

export const PERIODICITY_DAYS = [
  { code: 1, name: 'MONDAY' },
  { code: 2, name: 'TUESDAY' },
  { code: 3, name: 'WEDNESDAY' },
  { code: 4, name: 'THUSDAY' },
  { code: 5, name: 'FRIDAY' },
  { code: 6, name: 'SATURDAY' },
  { code: 0, name: 'SUNDAY' },
];

export const LANGUAGES = [
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'en', name: 'English' },
];
export const MEMBER_TYPE = [
  { code: 'FIRST_RESPONDER', name: 'FIRST_RESPONDER' },
  { code: 'EXTERNAL', name: 'EXTERNAL' },
];

export const GROUP_TYPE = [
  { code: true, name: 'IS_ALARM_GROUP' },
  { code: false, name: 'IS_NOT_ALARM_GROUP' },
];

export const CIVILITIES = [
  { code: 'CIVILITIE_MR', name: 'CIVILITIE_MR' },
  { code: 'CIVILITIE_MS', name: 'CIVILITIE_MS' },
];

export const FUNCTION_TYPE = [
  { code: 'MEMBER', name: 'FUNCTION_TYPE_MEMBER' },
  { code: 'GROUP', name: 'FUNCTION_TYPE_GROUP' },
  { code: 'GROUP_LEADER', name: 'FUNCTION_TYPE_GROUP_LEADER' },
];

export const MAP_FILE_TYPE = [
  { code: 'PDF', name: 'PDF' },
  { code: 'IMAGE', name: 'IMAGE' },
];

export const VEHICLE_TYPE = [
  { code: 'VCI', name: 'Vhc Chef Intervention' },
  { code: 'VTR', name: 'Vhc Tracteur' },
  { code: 'VTP', name: 'Vhc Transport Personnes' },
  { code: 'VCC', name: 'Vhc Conduite Cantonale' },
  { code: 'TP FR', name: 'Tonne-Pompe' },
  { code: 'TPL', name: 'Tonne-Pompe Lourd' },
  { code: 'SSO', name: 'Vhc SSO' },
  { code: 'VDC', name: 'Vhc Défense Chimie' },
  { code: 'VDH', name: 'Vhc Défense Hydrocarbures' },
  { code: 'VGM', name: 'Vhc Groupe Mesures' },
  { code: 'VPI', name: 'Vhc Pionnier' },
  { code: 'VST', name: 'Vhc Secours Technique' },
  { code: 'VTM', name: 'Vhc Transport Matériel' },
  { code: 'VEA', name: 'Vhc Echelle Automobile' },
  { code: 'VCI', name: 'Vhc CI' },
  { code: 'VCPONT', name: 'Vhc Pont' },
];
export const MATERIAL_TYPE = [
  { code: 'TRAILER', name: 'TRAILER' },
  { code: 'MODULE', name: 'MODULE' },
  { code: 'OTHER', name: 'OTHER' },
];

export const EVENT_TYPE = [
  { code: 'FIRE', name: 'EVENT_TYPE_FIRE', color: Colors.INCIDENT_TYPE.FIRE, shortCode: 'F' },
  { code: 'ACCIDENT', name: 'EVENT_TYPE_ACCIDENT', color: Colors.INCIDENT_TYPE.ACCIDENT, shortCode: 'A' },
  { code: 'RESCUE', name: 'EVENT_TYPE_RESCUE', color: Colors.INCIDENT_TYPE.RESCUE, shortCode: 'S' },
  { code: 'WATER', name: 'EVENT_TYPE_WATER', color: Colors.INCIDENT_TYPE.WATER, shortCode: 'E' },
  { code: 'HAZARD', name: 'EVENT_TYPE_HAZARD', color: Colors.INCIDENT_TYPE.HAZARD, shortCode: 'C' },
  { code: 'UNKNOWN', name: 'EVENT_TYPE_UNKNOWN', color: Colors.INCIDENT_TYPE.UNKNOWN, shortCode: '?' },
];

export const INCIDENT_LOG_ITEM_TYPES = [
  { code: 'FOLDER', name: 'FOLDER' },
  { code: 'ACTION', name: 'ACTION' },
];

export const INCIDENT_LOG_ENTRY_TYPES = [
  { code: 'COMMENT', name: 'COMMENT' },
  //  { code: 'ACTION', name: 'ACTION' },
];
export const B2_BASE_URL = 'https://files.alarmo.ch/';
