import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from 'react-i18next';
import api from 'src/api/axios';
import { useQueryClient } from 'react-query';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import Loader from 'src/shared/defaultLoader';

export default function MapFileImport({ onImportDone, fileId }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState(false);
  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);

  const myRefname = useRef(null);

  const changeHandler = async (event) => {
    const formData = new FormData();
    if (event.target.files.length > 0) {
      formData.append('file', event.target.files[0]);
      try {
        setIsLoading(true);
        const uploadRes = await api.post(`/map-files/${fileId}/import`, formData);
        event.target.value = null;
        queryClient.invalidateQueries(['map-files', { id: fileId }]);
        setIsLoading(false);
        if (uploadRes.data.res) {
          setFeedback({ msg: t('IMPORT_DONE'), severity: 'success' });
        } else {
          setFeedback({ msg: t(uploadRes.data.msg), severity: 'error' });
        }

        //onImportDone();
        return true;
      } catch (error) {
        event.target.value = null;
        setFeedback({ msg: t('IMPORT_ERROR'), severity: 'error' });

        console.error(error);
        return false;
      }
    }
  };

  const handleSubmission = () => {
    myRefname.current.click();
  };

  return (
    <div>
      <input type="file" name="file" onChange={changeHandler} ref={myRefname} style={{ display: 'none' }} />
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Button size="small" variant="contained" startIcon={<FileUploadIcon />} onClick={handleSubmission}>
            {t('IMPORT')}
          </Button>
        )}
      </div>
    </div>
  );
}
