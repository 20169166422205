import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAllData, get } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Colors from 'src/styles/variables';
import TableHead from '@mui/material/TableHead';
import Standing from 'src/icons/standing';
import Running from 'src/icons/running';
import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import { useQueryClient } from 'react-query';
import useWindowSize from 'src/hooks/useWindowSize';

export default function WorkforceSummary({ date = '', displayAll = false }) {
  const { t } = useTranslation();

  const size = useWindowSize();

  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  const query = () => get(`board/raw${date !== '' ? '/' + date.toISOString() : ''}`);
  const { data, error, isLoading, isError } = useQuery(['workForce', { date: date }], query, {
    refetchInterval: 10000,
  });

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  // const getRolesQueryFn = async () => await getAllData('role');
  // const { data: roles, isLoading: roleIsLoading } = useQuery('roles', getRolesQueryFn);

  if (isLoading || specializationIsLoading) {
    return <Loader />;
  }

  const totalAvailable = data.reduce((previousValue, currentValue) => {
    if (currentValue.status_code === 'AVAILABLE') {
      return previousValue + 1;
    } else {
      return previousValue;
    }
  }, 0);

  // let rolesData = [];
  // for (const role of roles) {
  //   const number = data.reduce(
  //     (previousValue, currentValue) => {
  //       if (currentValue.roles.includes(role.role_name)) {
  //         previousValue.total++;
  //         if (currentValue.status_code === 'AVAILABLE') {
  //           previousValue.available++;
  //         }
  //       }
  //       return previousValue;
  //     },
  //     { total: 0, available: 0 }
  //   );

  //   if (number.total > 0 && (role.is_on_summary || displayAll) && role.role_name !== 'ADMIN') {
  //     rolesData.push({ name: role.role_abbreviation, number: number, color: role.color });
  //   }
  // }

  let specData = [];
  for (const spec of specializations) {
    const number = data.reduce(
      (previousValue, currentValue) => {
        if (currentValue.specializations.includes(spec.specialization_abbreviation)) {
          previousValue.total++;
          if (currentValue.status_code === 'AVAILABLE') {
            previousValue.available++;
          }
          if (currentValue.alarm_id) {
            previousValue.on_incident++;
          }
        }
        return previousValue;
      },
      { total: 0, available: 0, on_incident: 0 }
    );

    if (number.total > 0 && (spec.is_on_summary || displayAll)) {
      specData.push({ name: spec.specialization_name, number: number, color: spec.color, min_requierement: spec.min_requierement });
    }
  }

  const total_on_incident = data.reduce((previousValue, currentValue) => {
    if (currentValue.alarm_id) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  let minColor = Colors.Summary.normal;
  if (globalParam && globalParam?.minimum_work_force && globalParam?.minimum_work_force > 0 && totalAvailable < globalParam?.minimum_work_force) {
    minColor = Colors.Summary.bellowMin;
  }

  return (
    <Stack spacing={1}>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: '0px', minWidth: 5, width: 5, p: 0 }}></TableCell>
              <TableCell
                sx={{
                  border: '0px',
                  minWidth: { xs: size.width - 230, se: 150 },
                  width: { xs: size.width - 230, se: 150 },
                  maxWidth: { xs: size.width - 230, se: 150 },
                  p: '4px',
                }}
              >
                &nbsp;
              </TableCell>
              <TableCell sx={{ border: '0px', p: '4px' }}>&nbsp;</TableCell>
              <TableCell sx={{ border: '0px', minWidth: 75, width: 75, maxWidth: 75, p: '4px' }} align="center">
                <Box component={Standing} sx={{ color: Colors.AVAILABLE.darker, fontSize: 18, verticalAlign: 'sub' }} />/
                <Box component={Standing} sx={{ color: 'black', fontSize: 18, verticalAlign: 'sub' }} />
              </TableCell>

              {total_on_incident > 0 ? (
                <TableCell sx={{ border: '0px', minWidth: 45, width: 45, maxWidth: 45, p: '4px' }} align="center">
                  <Box component={Running} sx={{ color: Colors.ALARM.darker, fontSize: 18, verticalAlign: 'sub' }} />
                </TableCell>
              ) : null}

              <TableCell sx={{ border: '0px', minWidth: 45, width: 45, maxWidth: 45, p: '4px' }} align="center">
                <Box component={RemoveIcon} sx={{ color: 'black', fontSize: 18, verticalAlign: 'sub' }} />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, p: '4px' }}>
              <TableCell sx={{ minWidth: 5, width: 5, backgroundColor: Colors.ALARM.darker, p: '4px' }}></TableCell>
              <TableCell
                sx={{
                  minWidth: { xs: size.width - 230, se: 150 },
                  width: { xs: size.width - 230, se: 150 },
                  maxWidth: { xs: size.width - 230, se: 150 },
                  p: '4px',
                }}
              >
                {t('TOTAL_WORKFORCE_ENGAGED')}
              </TableCell>
              <TableCell sx={{ p: '4px' }} align="center">
                &nbsp;
              </TableCell>
              <TableCell sx={{ minWidth: 75, width: 75, maxWidth: 75, p: '4px', color: minColor }} align="center">
                {totalAvailable} / {data.length}
              </TableCell>
              {total_on_incident > 0 ? (
                <TableCell sx={{ minWidth: 45, width: 45, maxWidth: 45, p: '4px' }} align="center">{`${total_on_incident}`}</TableCell>
              ) : null}
              <TableCell sx={{ minWidth: 45, width: 45, maxWidth: 45, p: '4px' }} align="center">
                {globalParam?.minimum_work_force ? `(${globalParam.minimum_work_force})` : ' '}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {specData.map((m, index) => {
              let minColor = Colors.Summary.normal;
              if (m.min_requierement && m.number.available < m.min_requierement) {
                minColor = Colors.Summary.bellowMin;
              }
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ minWidth: 5, width: 5, p: '4px', backgroundColor: m.color }}></TableCell>
                  <TableCell
                    sx={{
                      minWidth: { xs: size.width - 230, se: 150 },
                      width: { xs: size.width - 230, se: 150 },
                      maxWidth: { xs: size.width - 230, se: 150 },
                      p: '4px',
                    }}
                  >
                    {t(m.name)}
                  </TableCell>
                  <TableCell sx={{ p: '4px' }} align="center">
                    &nbsp;
                  </TableCell>

                  <TableCell
                    sx={{
                      minWidth: 75,
                      width: 75,
                      maxWidth: 75,
                      p: '4px',
                      color: minColor,
                    }}
                    align="center"
                  >
                    {m.number.available} / {m.number.total}
                  </TableCell>
                  {total_on_incident > 0 ? (
                    <TableCell sx={{ minWidth: 45, width: 45, maxWidth: 45, p: '4px' }} align="center">
                      {m.number.on_incident > 0 ? `${m.number.on_incident}` : ``}
                    </TableCell>
                  ) : null}
                  <TableCell sx={{ minWidth: 45, width: 45, maxWidth: 45, p: '4px' }} align="center">
                    {m.min_requierement > 0 ? `(${m.min_requierement})` : ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
