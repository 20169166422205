import React from 'react';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';

import { useUIContext } from '../../contexts/ui/ui.context';
import { useHistory } from 'react-router-dom';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';

function Line({ spec }) {
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Button
        icon={DryCleaningIcon}
        iconColor={Colors.button_default_icon}
        bgColor={Colors.button_default}
        onClick={() => history.push(`/user/specialization/${spec.specialization_id}`)}
      >
        {spec.specialization_name}
      </Button>
    </Grid>
  );
}

export default function Specializations() {
  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('SPECIALIZATIONS'));
  }, []);

  const query = () => getAllData('specialization');
  const { data, error, isLoading, isError } = useQuery('specializations', query);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      {data.map((spec) => {
        return <Line key={spec.specialization_id} spec={spec} />;
      })}
    </Grid>
  );
}
