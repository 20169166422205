import React from 'react';
import Box from '@mui/material/Box';
import { Colors } from 'src/styles/variables';

import './button.scss';

export default function Button({
  children,
  icon,
  bgColor = Colors.button_default,
  iconColor = Colors.button_default_icon,
  textColor = 'text.secondary',
  textSize = 16,
  onClick,
}) {
  function createRipple(event) {
    const button = event.currentTarget;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple');

    const ripple = button.getElementsByClassName('ripple')[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
    if (onClick) {
      onClick();
    }
  }

  return (
    <Box
      className={'customMobileButton'}
      onClick={createRipple}
      sx={{
        bgcolor: bgColor,
        boxShadow: 1,
        borderRadius: 1,
        p: 1,
      }}
      // css={css`
      //   :hover {
      //     filter: brightness(85%);
      //   }
      // `}
    >
      <Box
        sx={{
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box component={icon} sx={{ color: iconColor, fontSize: 32, verticalAlign: 'sub' }} />
      </Box>
      <Box
        sx={{
          color: textColor || 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          fontSize: textSize,
          fontWeight: 'bold',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
