import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import WeekSelector from 'src/shared/weekSelector';
import Loader from 'src/shared/defaultLoader';
// import WeeklyPlanning from './weeklyPlanning';
import { DateUtiles } from 'src/services/dateUtiles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
// import PlanningPushModal from './planningPushModal';
// import useStore from './planning.store';
import WeeklyPlanningView from './weeklyPlanningView';
import WeeklyCalendarView from './weeklyCalendarView';
import CalendarView from './calendarView';
import { useUIContext } from '../../contexts/ui/ui.context';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MyDuty from 'src/modules/user/pages/myDuty/myDuty';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
  marginTop: theme.spacing(2),
}));

export default function UserPlanning() {
  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('ON_DUTY'));
  }, []);
  const [baseDate, setBaseDate] = useState(new Date());

  const [viewType, setViewType] = React.useState('CALENDAR');

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setViewType(newType);
    }
  };
  const getQueryFn = async ({ queryKey }) => await get('function/list/weekly');
  const { data, isLoading } = useQuery(['function/list/weekly'], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ToggleButtonGroup color="primary" value={viewType} exclusive fullWidth onChange={handleChange}>
          <ToggleButton value="CALENDAR">{t('CALENDAR')}</ToggleButton>
          {/* <ToggleButton value="PLANNING">{t('PLANNING')}</ToggleButton> */}
          <ToggleButton value="MY_DUTY">{t('MY_DUTY')}</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {viewType === 'CALENDAR' ? (
          <>
            <Grid item xs={12}>
              <WeekSelector baseDate={baseDate} onWeekChange={(newDate) => setBaseDate(newDate)} />
            </Grid>
            <CalendarView data={data} baseDate={baseDate} />
          </>
        ) : null}
        {viewType === 'PLANNING' ? (
          <>
            <Grid item xs={12}>
              <WeekSelector baseDate={baseDate} onWeekChange={(newDate) => setBaseDate(newDate)} />
            </Grid>
            <PlanningView data={data} baseDate={baseDate} />
          </>
        ) : null}
        {viewType === 'MY_DUTY' ? <MyDuty /> : null}
      </Grid>
    </Grid>
  );
}

function PlanningView({ data, baseDate }) {
  return (
    <>
      {data.map((func) => (
        <Item key={func.function_id}>
          <WeeklyPlanningView
            functionDetails={func}
            year={DateUtiles.getYear(DateUtiles.getMonday(baseDate))}
            weekNumber={DateUtiles.getWeekNumber(DateUtiles.getMonday(baseDate))}
          />
        </Item>
      ))}
    </>
  );
}
