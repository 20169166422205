import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';

export default function PlanningPushModal({ onConfirm, isDisabled, type = 'GLOBAL' }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState('WEEK');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant={type === 'GLOBAL' ? 'contained' : 'outlined'}
        size="large"
        fullWidth
        disabled={isDisabled}
        onClick={() => {
          if (type === 'GLOBAL') {
            setOpen(true);
          } else {
            handleClose();
            onConfirm('WEEK');
          }
          // deleteCurrentRecord;
        }}
      >
        {type === 'GLOBAL' ? t('PUSH_TO_CALENDAR') : t('LINE_PUSH_TO_CALENDAR')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>{t('Pousser le planning dans le calendrier')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Voulez-vous vraiment pousser le planning dans le calendrier? Cela effassera toutes les fonctions assignée durant la periode séléctionnée.')}
          </DialogContentText>
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <FormLabel component="legend">{t('Pousser les assignations sur')} :</FormLabel>
            <RadioGroup value={value} onChange={handleChange} name="radio-buttons-group">
              <FormControlLabel value="WEEK" control={<Radio />} label="La semaine séléctionnée" />
              <FormControlLabel value="YEAR" control={<Radio />} label="L'année séléctionnée" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm(value);
            }}
            autoFocus
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
