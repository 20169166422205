/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Box from '@mui/material/Box';
// import Colors from 'src/styles/variables';
import './statusSelectorButton.scss';
import { Colors } from 'src/styles/variables';

export default function StatusSelectorButton({
  children,
  icon,
  bgColor = Colors.button_default,
  iconColor = Colors.button_default_icon,
  textColor = 'text.secondary',
  textSize = 16,
  onClick,
  pressed = false,
}) {
  function createRipple(event) {
    const button = event.currentTarget;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple');

    const ripple = button.getElementsByClassName('ripple')[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
    pressed = !pressed;
    if (onClick) {
      onClick();
    }
  }

  return (
    <Box
      className={'customMobileButton'}
      onClick={createRipple}
      sx={{
        bgcolor: bgColor,
        boxShadow: 2,
        borderRadius: 1,
        p: 2,
      }}
    >
      <Box
        sx={{
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box component={icon} sx={{ color: iconColor, fontSize: 48, verticalAlign: 'sub' }} />
      </Box>
      <Box
        sx={{
          color: textColor,
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          fontSize: textSize,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
