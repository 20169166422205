import React from 'react';
import { TreeItem, treeItemClasses, useTreeItemState } from '@mui/x-tree-view/TreeItem';

import { alpha, styled } from '@mui/material/styles';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

const StyledTreeItem = styled((props) => <TreeItem ContentComponent={CustomContent} {...props} />)(({ theme }) => {
  return {
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  };
});

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const { classes, className, label, itemId, icon: iconProp, expansionIcon, displayIcon } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } = useTreeItemState(itemId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

export default function CustomTreeItem({ e, actionDisable = true }) {
  //return 'test';
  return (
    <StyledTreeItem
      itemId={e.incident_log_preset_item_id.toString()}
      label={`${e.name}`}
      slots={{ endIcon: e.item_type === 'ACTION' ? ReceiptLongIcon : FolderOpenIcon, collapseIcon: KeyboardArrowDownIcon, expandIcon: KeyboardArrowRightIcon }}
      disabled={e.item_type === 'ACTION' && actionDisable}
    >
      {e.children.map((child) => {
        return <CustomTreeItem key={child.incident_log_preset_item_id} e={child} actionDisable={actionDisable} />;
      })}
    </StyledTreeItem>
  );
}
