import React from 'react';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';
import Loader from 'src/shared/defaultLoader';
import Button from '@mui/material/Button';
import VehicleMobiliseButton from './vehicleMobiliseButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { TodayTwoTone } from '@mui/icons-material';
import MaterialButton from './materialButton';
import MaterialMobiliseButton from './materialMobiliseButton';

export default function Materials() {
  const { t } = useTranslation();
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const groupQuery = () => getAllData('material/withState');
  const { data, isLoading: materialsIsLoading } = useQuery('material/withState', groupQuery);

  if (materialsIsLoading) {
    return <Loader />;
  }

  const total_engaged = data.reduce((previousValue, material) => {
    if (material.material_status && material.incident_id !== null) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_not_available = data.reduce((previousValue, material) => {
    if (material.material_status && material.incident_id === null) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_available = data.reduce((previousValue, material) => {
    if (!material.material_status) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  return (
    <React.Fragment>
      {total_engaged ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '0px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('MATERIALS_LINKED')}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
            {data.map((material) => {
              if (material.material_status && material.incident_id !== null) {
                return (
                  <Grid key={material.material_id} item xs={12}>
                    <MaterialButton material={material} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      ) : null}

      {total_available ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '15px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('MATERIALS_AVAILABLE')}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {data.map((material) => {
              if (!material.material_status) {
                return (
                  <React.Fragment key={material.material_id}>
                    {isIC ? (
                      <Grid item xs={2}>
                        <MaterialMobiliseButton material={material} />
                      </Grid>
                    ) : null}

                    <Grid item xs={isIC ? 10 : 12}>
                      <MaterialButton material={material} />
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </>
      ) : null}

      {total_not_available ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '15px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('MATERIALS_NOT_AVAILABLE')}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {data.map((material) => {
              if (material.material_status && material.incident_id === null) {
                return (
                  <React.Fragment key={material.material_id}>
                    <Grid item xs={12}>
                      <MaterialButton material={material} />
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </>
      ) : null}
    </React.Fragment>
  );
}
