import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import frLocale from 'date-fns/locale/fr-CH';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/List';
import { Controller } from 'react-hook-form';

const units = [
  { id: 'WEEK', name: 'WEEK' },
  { id: 'DAY', name: 'DAY' },
];

const types = [
  { id: 'SECTION', name: 'SECTION' },
  { id: 'GROUP', name: 'FUNCTION_TYPE_GROUP' },
];

export default function DisplayShift() {
  const { register, handleSubmit, control, setValue } = useForm();
  const defaultDate = new Date();
  // setValue('start_date', defaultDate, { shouldValidate: true, shouldDirty: true });

  const [fromDate, setFromDate] = React.useState(defaultDate);
  const [fromTime, setFromTime] = React.useState(defaultDate);
  const history = useHistory();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('display_shift', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['display_shift', { id }], getQueryFn, {
    onSuccess: (data) => {
      const existingDate = new Date(data.start_date);
      setFromDate(existingDate);
      setFromTime(existingDate);
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  // const onSubmit = handleSubmit(async (data) => {
  //   console.log(data);
  //   if (id !== '0') {
  //     await mutateAsyncUpdate({ ...data, id });
  //   } else {
  //     await mutateAsyncCreate(data);
  //   }
  //   history.goBack();
  // });

  const onDetailClicked = () => {
    // console.log(data.shift_type);
    if (data.shift_type === 'SECTION') {
      history.push(`${id}/details`);
    } else {
      history.push(`${id}/details_group`);
    }
  };
  // console.log(id);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ pl: 3, pr: 3, pb: 1, pt: 1, mb: 1 }}>
        <Stack spacing={1} direction="row">
          <Button size="small" type="submit" variant="contained" startIcon={<ListIcon />} disabled={id === '0'} onClick={onDetailClicked}>
            {t('DETAILS')}
          </Button>
        </Stack>
      </Paper>
      <GenericCRUD objectName={'display_shift'} handleSubmit={handleSubmit} data={data}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('shift_name')}
              required
              autoComplete="off"
              id="shift_name"
              name="shift_name"
              label={t('NAME')}
              fullWidth
              variant="standard"
              defaultValue={data.shift_name}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="shift_type"
            name="shift_type"
            label={t('SHIFT_TYPE')}
            control={control}
            defaultValue={data.shift_type}
            variant="standard"
            disabled={id !== '0'}
          >
            {types.map((type) => {
              return (
                <MenuItem key={type.id} value={type.id}>
                  {t(type.name)}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('frequency')}
              required
              autoComplete="off"
              id="frequency"
              name="frequency"
              label={t('FREQUENCY')}
              fullWidth
              variant="standard"
              defaultValue={data.frequency || '1'}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            register={register}
            id="unit"
            name="unit"
            label={t('UNIT')}
            control={control}
            defaultValue={data.unit || 'WEEK'}
            variant="standard"
          >
            {units.map((unit) => {
              return (
                <MenuItem key={unit.id} value={unit.id}>
                  {t(unit.name)}
                </MenuItem>
              );
            })}
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth={true}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <Controller
                control={control}
                id="start_date"
                name="start_date"
                defaultValue={fromDate}
                render={({ field }) => (
                  <StaticDatePicker
                    {...field}
                    toolbarTitle={t('START_DATE')}
                    value={fromDate}
                    onChange={(newValue) => {
                      fromTime.setDate(newValue.getDate());
                      fromTime.setMonth(newValue.getMonth());
                      fromTime.setFullYear(newValue.getFullYear());
                      setFromTime(fromTime);
                      setValue('start_time', fromTime, { shouldValidate: true, shouldDirty: true });
                      field.onChange(newValue);
                      setFromDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth={true}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <Controller
                control={control}
                id="start_time"
                name="start_time"
                defaultValue={fromTime}
                render={({ field }) => (
                  <StaticTimePicker
                    {...field}
                    displayStaticWrapperAs="mobile"
                    toolbarTitle={t('START_TIME')}
                    value={fromTime}
                    onChange={(newValue) => {
                      fromDate.setHours(newValue.getHours());
                      fromDate.setMinutes(newValue.getMinutes());
                      fromDate.setSeconds(0);
                      fromDate.setMilliseconds(0);
                      newValue.setSeconds(0);
                      newValue.setMilliseconds(0);
                      setFromDate(fromDate);
                      setValue('start_date', fromDate, { shouldValidate: true, shouldDirty: true });
                      field.onChange(newValue);
                      setFromTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </GenericCRUD>
    </Box>
  );
}
