import React from 'react';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';
import Loader from 'src/shared/defaultLoader';
import VehicleButton from './vehicleButton';
import Button from '@mui/material/Button';
import VehicleMobiliseButton from './vehicleMobiliseButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { TodayTwoTone } from '@mui/icons-material';
import MaterialButton from './materialButton';

export default function Vehicle() {
  const { t } = useTranslation();
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const groupQuery = async () => await getAllData('vehicle/withState');
  const { data, isLoading: vehicleIsLoading } = useQuery('vehicle/withState', groupQuery);

  const materialQuery = async () => await getAllData('material/withState');
  const { data: materialData, isLoading: materialsIsLoading } = useQuery('material/withState', materialQuery);

  if (vehicleIsLoading || materialsIsLoading) {
    return <Loader />;
  }

  const total_engaged_vhc = data.reduce((previousValue, vehicle) => {
    if (vehicle.vehicle_status && vehicle.incident_id !== null) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_not_available_vhc = data.reduce((previousValue, vehicle) => {
    if (vehicle.vehicle_status && vehicle.vehicle_status !== 'TRAINING' && vehicle.incident_id === null) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_training_vhc = data.reduce((previousValue, vehicle) => {
    if (vehicle.vehicle_status === 'TRAINING') {
      previousValue++;
    }
    return previousValue;
  }, 0);

  const total_available_vhc = data.reduce((previousValue, vehicle) => {
    if (!vehicle.vehicle_status) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  return (
    <React.Fragment>
      {total_engaged_vhc ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '0px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('VEHICLES_LINKED')}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={1}>
            {data.map((vehicle) => {
              if (vehicle.vehicle_status && vehicle.incident_id !== null) {
                return (
                  <React.Fragment key={vehicle.vehicle_id}>
                    <Grid item xs={12}>
                      <VehicleButton vehicle={vehicle} />
                    </Grid>
                    {materialData.map((material) => {
                      if (material.vehicle_id === vehicle.vehicle_id) {
                        return (
                          <React.Fragment key={material.material_id}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                              {/* {material.material_name} */}
                              <MaterialButton size="small" material={material} />
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </>
      ) : null}

      {total_available_vhc ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '15px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('VEHICLES_AVAILABLE')}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {data.map((vehicle) => {
              if (!vehicle.vehicle_status) {
                return (
                  <React.Fragment key={vehicle.vehicle_id}>
                    {isIC ? (
                      <Grid item xs={2}>
                        <VehicleMobiliseButton vehicle={vehicle} />
                      </Grid>
                    ) : null}

                    <Grid item xs={isIC ? 10 : 12}>
                      <VehicleButton vehicle={vehicle} noExtraIcon={isIC} />
                    </Grid>
                    {materialData.map((material) => {
                      if (material.vehicle_id === vehicle.vehicle_id) {
                        return (
                          <React.Fragment key={material.material_id}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                              {/* {material.material_name} */}
                              <MaterialButton size="small" material={material} />
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </>
      ) : null}

      {total_training_vhc ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '15px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('VEHICLES_TRAINNING')}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={1}>
            {data.map((vehicle) => {
              if (vehicle.vehicle_status === 'TRAINING') {
                return (
                  <React.Fragment key={vehicle.vehicle_id}>
                    <Grid item xs={12}>
                      <VehicleButton vehicle={vehicle} />
                    </Grid>
                    {materialData.map((material) => {
                      if (material.vehicle_id === vehicle.vehicle_id) {
                        return (
                          <React.Fragment key={material.material_id}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                              {/* {material.material_name} */}
                              <MaterialButton size="small" material={material} />
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </>
      ) : null}

      {total_not_available_vhc ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '15px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('VEHICLES_NOT_AVAILABLE')}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
            {data.map((vehicle) => {
              if (vehicle.vehicle_status && vehicle.incident_id === null && vehicle.vehicle_status != 'TRAINING') {
                return (
                  <Grid key={vehicle.vehicle_id} item xs={12}>
                    <VehicleButton vehicle={vehicle} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      ) : null}
    </React.Fragment>
  );
}
