import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useStore from './reinforcement.store';
import { useParams } from 'react-router-dom';
import { post, get, getAllData } from 'src/api/api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import Typography from '@mui/material/Typography';
import AlertDialog from 'src/shared/sharedModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import { useUIContext } from '../../contexts/ui/ui.context';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ReinforcementMessage() {
  let { type } = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [feedBack, setFeedBack] = React.useState({ msg: '', severity: '' });
  const feedback = useStoreGlobalFeedback((state) => state.feedback);
  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);
  const history = useHistory();

  const [alarmMessage, setAlarmMessage] = React.useState('');
  const [alarmAddress, setAlarmAddress] = React.useState('');
  const [alarmType, setAlarmType] = React.useState('');
  const [alarmExtraMessage, setAlarmExtraMessage] = React.useState('');

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('REINFORCEMENTS_MESSAGE'));
  }, []);

  React.useEffect(() => {
    let alarmTypeMessage = '';
    if (alarmTypes) {
      const alarmTypeFound = alarmTypes.find((type) => type.alarm_type_id === alarmType);
      if (alarmTypeFound) {
        alarmTypeMessage = alarmTypeFound.alarm_description;
      }
    }
    const newMessage = ['MOBILISATION', alarmTypeMessage, alarmExtraMessage, alarmAddress].filter(Boolean).join(' - ');
    setAlarmMessage(newMessage);
  }, [alarmExtraMessage, alarmAddress, alarmType]);

  const getSelectedMembers = useStore((state) => state.getSelectedMembers);
  const selectedMembers = getSelectedMembers();
  const memberData = useStore((state) => state.membersData);

  const groupQuery = () => getAllData('alarm/types');
  const { data: alarmTypes, isLoading } = useQuery('alarm/types', groupQuery);

  const selectedMembersData = memberData.filter((member) => {
    if (type === 'available') {
      return selectedMembers.has(member.member_id) && member.status_code === 'AVAILABLE';
    } else {
      return selectedMembers.has(member.member_id);
    }
  });

  let geolocationAvailable = false;
  if ('geolocation' in navigator) {
    geolocationAvailable = true;
  }

  const [chipData, setChipData] = React.useState(selectedMembersData);

  const handleDelete = (memberToDelete) => () => {
    setChipData((selectedMembersData) => selectedMembersData.filter((member) => member.member_id !== memberToDelete.member_id));
  };

  const btnSendClicked = async () => {
    const res = await post('reinforcement/sendMessage', { toMembers: chipData.map((member) => member.member_id), message: alarmMessage });
    // console.log(res);
    if (!res.res) {
      setFeedBack({ msg: res.msg, severity: 'error' });
    } else {
      setFeedback({ msg: t('ALARM_SENT'), severity: 'success' });
      history.push(`/user`);
    }
  };
  const btnLocationClicked = async () => {
    if (geolocationAvailable) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          // console.log('Latitude is :', position.coords.latitude);
          // console.log('Longitude is :', position.coords.longitude);
          const currentPosition = await get('utils/revgeocode', { long: position.coords.longitude, lat: position.coords.latitude });
          // console.log(currentPosition);
          if (currentPosition.items.length > 0) {
            const geo = currentPosition.items[0].address;
            if (geo.label) {
              setAlarmAddress(`${geo.label}`);
            } else {
              setAlarmAddress(`${geo.street}${geo.houseNumber ? ' ' + geo.houseNumber : ''}, ${geo.postalCode} ${geo.city}`);
            }
          }
        },
        null,
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0,
        }
      );
    }
  };

  const handleExtraMessageChange = (event) => {
    setAlarmExtraMessage(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAlarmAddress(event.target.value);
  };

  const handleTypeChange = (event) => {
    setAlarmType(event.target.value);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">{t('ALARM_TYPE')}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={alarmType} onChange={handleTypeChange}>
              <MenuItem value={''}>&nbsp;</MenuItem>
              {alarmTypes.map((type) => {
                return (
                  <MenuItem key={type.alarm_type_id} value={type.alarm_type_id}>
                    {`${type.alarm_code} - ${type.alarm_description}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={alarmExtraMessage}
            onChange={handleExtraMessageChange}
            fullWidth
            label={t('ALARM_EXTRA_MESSAGE')}
            multiline
            rows={3}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField value={alarmAddress} onChange={handleAddressChange} fullWidth label={t('ALARM_ADDRESS')} multiline rows={3} variant="standard" />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TextField
            value={alarmMessage}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            label={t('ALARM_MESSAGE')}
            multiline
            rows={3}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <Typography variant="caption" display="block" gutterBottom>
              {alarmMessage.length}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            {geolocationAvailable ? (
              <Button sx={{ height: '46px', minHeight: '46px' }} variant="outlined" onClick={btnLocationClicked}>
                {t('GET_LOCATION')}
              </Button>
            ) : null}
            <Button
              sx={{ height: '46px', minHeight: '46px' }}
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
            >
              {t('SEND')}
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {chipData.map((member) => {
              let icon;

              return (
                <ListItem key={member.member_id}>
                  <Chip icon={icon} label={`${member.firstname.charAt(0)}. ${member.lastname}`} onDelete={handleDelete(member)} />
                </ListItem>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        Message={t('CONFIRM_SEND_ALARM_SMS', { nb_receiver: chipData.map((member) => member.member_id).length })}
        onConfirm={btnSendClicked}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={feedBack.msg !== ''}
        autoHideDuration={6000}
        onClose={() => {
          setFeedBack({ msg: '', severity: '' });
        }}
      >
        <Alert
          onClose={() => {
            setFeedBack({ msg: '', severity: '' });
          }}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t(feedBack.msg)}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
