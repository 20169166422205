import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function InterventionLogCategoriesList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'incident_log_preset_category_id', headerName: 'ID', hide: true },
    {
      field: 'name',
      headerName: t('CATEGORY_NAME'),
      width: 250,
      flex: 1,
    },
    {
      field: 'category_order',
      headerName: t('CATEGORY_ORDER'),
      width: 75,
      flex: 1,
    },
  ];

  return <GenericList objectName={'intervention-log-category'} columns={columns} rowId={'incident_log_preset_category_id'} />;
}
