import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import PopulatedSelect from 'src/shared/populatedSelect';

export default function Specialization() {
  const { register, handleSubmit, control } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('specialization', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['specialization', { id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'specialization'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('specialization_name')}
            required
            autoComplete="off"
            id="specialization_name"
            name="specialization_name"
            label={t('SPECIALIZATION')}
            fullWidth
            variant="standard"
            defaultValue={data.specialization_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('specialization_abbreviation')}
            required
            id="specialization_abbreviation"
            name="specialization_abbreviation"
            label={t('ABBREVIATION')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.specialization_abbreviation}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('min_requierement')}
            id="min_requierement"
            name="min_requierement"
            label={t('MIN_REQUIEREMENT')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.min_requierement}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('specialization_order')}
            id="specialization_order"
            name="specialization_order"
            label={t('SPECIALIZATION_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.specialization_order}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('color')}
            id="color"
            name="color"
            label={t('COLOR_CODE')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.color}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('source_mapping')}
            id="source_mapping"
            name="source_mapping"
            label={t('SOURCE_MAPPING')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.source_mapping}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <PopulatedSelect
          control={control}
          value={data.role_id}
          endoint={'role'}
          name={'role_id'}
          id={'role_id'}
          label={t('ROLE')}
          withBlankOption
          displayAttribute={'role_name'}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        {/* <FormControl variant="standard">
          <FormControlLabel
            control={<Switch {...register('is_on_display')} defaultChecked={data.is_on_display} name="is_on_display" />}
            label={t('IS_ON_DISPLAY')}
            labelPlacement="start"
          />
        </FormControl> */}
        <FormControl variant="standard">
          <FormControlLabel
            control={<Switch {...register('is_on_summary')} defaultChecked={data.is_on_summary} name="is_on_summary" />}
            label={t('IS_ON_SUMMARY')}
            labelPlacement="start"
          />
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
