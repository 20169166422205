import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import MemberSelector from 'src/shared/memberSelector';
import Button from '../../components/button/button';
import StarRateIcon from '@mui/icons-material/StarRate';
import { useTheme } from '@mui/material/styles';
import { getAllData } from 'src/api/api';
import { updateData } from 'src/api/api';
import { useHistory } from 'react-router-dom';

export default function IncidentNewIC() {
  const theme = useTheme();
  const history = useHistory();
  const [incidentCommanderId, setIncidentCommanderId] = React.useState(null);
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('incident', { queryKey });
  const inputRef = React.useRef();
  const { data, error, isLoading, isError } = useQuery(['incidentDetail', { id }], getQueryFn, {
    onSuccess: (data) => {
      setIncidentCommanderId('incident_commander_id', data?.incident_commander_id ?? null);
    },
  });

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  if (specializationIsLoading || isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  const ciSpec = specializations.find((spec) => spec.role_abbreviation === 'CI');
  const contrastTextColor = theme.palette.getContrastText(ciSpec.color);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MemberSelector
          defaultMemberId={data.incident_commander_id}
          label={t('INCIDENT_COMMANDER')}
          onChange={(v) => {
            setIncidentCommanderId(v?.member_id ?? null);
          }}
          inputRef={inputRef}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          icon={StarRateIcon}
          bgColor={ciSpec.color}
          textColor={contrastTextColor}
          iconColor={contrastTextColor}
          onClick={async () => {
            await updateData(`incident/${id}/incidentCommander`, { member_id: incidentCommanderId });
            history.push(`/user`);
          }}
        >
          {t('NEW_IC')}
        </Button>
      </Grid>
    </Grid>
  );
}
