import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { getAllData, post } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import IncidentsCard from '../card/incidentCard';
import { useHistory } from 'react-router-dom';

export default function OpenIncidentsList() {
  const history = useHistory();

  const { t } = useTranslation();
  const query = () => getAllData('incident');

  const { data, error, isLoading, isError } = useQuery('incident', query);

  if (isLoading) {
    return <Loader />;
  }

  const onIncidentSelected = async function (incident) {
    console.log(incident);
    const res = await post('alarm/receipt_by_incident', incident);
    history.push(`/user`);
  };

  return (
    <>
      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '15',
        }}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {`${t('SELECT_OPEN_INCIDENT')}`}
          </Typography>
          <Box sx={{ m: 'auto', width: '70%', mb: '10px' }}>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Grid item xs={12}>
        {data?.map((incident) => {
          if (incident.incident_status === 'OPEN') {
            return <IncidentsCard key={incident.incident_id} incident={incident} withClick={true} onClick={onIncidentSelected} />;
          }
        })}
      </Grid>
    </>
  );
}
