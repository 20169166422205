import React, { useState } from 'react';
import Button from '../../button/button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { get } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import { post } from 'src/api/api';

export default function NotAvailableButton() {
  const { t } = useTranslation();

  let { url } = useRouteMatch();
  const history = useHistory();

  const [isAvailable, setIsAvailable] = useState();

  const queryClient = useQueryClient();
  const userParam = queryClient.getQueryData(['params/currentUser']);

  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading } = useQuery(['currentUserStatus'], getQueryFn, {
    onSuccess: (data) => {
      setIsAvailable(data.availability === 'AVAILABLE');
    },
  });

  const quickStatusClickHandler = async (status) => {
    if (userParam?.availability_type_input === 'QUICK') {
      let days = '1000',
        hours = '0',
        minutes = '0';
      if (userParam?.default_time_days != '' || userParam?.default_time_hours != '' || userParam?.default_time_minutes != '') {
        days = userParam?.default_time_days ?? '0';
        hours = userParam?.default_time_hours ?? '0';
        minutes = userParam?.default_time_minutes ?? '0';
      }
      await post('availability/quick', {
        status: 'NOT_AVAILABLE',
        days: days,
        hours: hours,
        minutes: minutes,
      });
      queryClient.invalidateQueries('currentUserStatus');
      queryClient.invalidateQueries('workForce');
    } else {
      history.push(`${url}/quickStatusUpdate/${status}/:status`);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Button
      icon={DoNotDisturbOnIcon}
      textColor={!isAvailable ? Colors.NOT_AVAILABLE.darker : null}
      iconColor={Colors.NOT_AVAILABLE.darker}
      bgColor={!isAvailable ? Colors.NOT_AVAILABLE.light : Colors.button_default}
      onClick={() => quickStatusClickHandler('NOT_AVAILABLE')}
    >
      {t('NOT_AVAILABLE')}
    </Button>
  );
}
