import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { updateData } from 'src/api/api';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import { LANGUAGES } from 'src/constants/constants';
import MenuItem from '@mui/material/MenuItem';
import { useUIContext } from '../../contexts/ui/ui.context';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SmsQuota from './smsQuota';

export default function Parameters() {
  const { register, handleSubmit, control } = useForm();
  const objectName = 'params/global';
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();
  const localConfig = queryClient.getQueryData(['local/config']);

  const data = queryClient.getQueryData([objectName]);

  const updateQueryFn = async ({ ...data }) => await updateData(objectName, { ...data });
  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([objectName], data);
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsyncUpdate({ ...data });
    queryClient.invalidateQueries([objectName]);
    queryClient.refetchQueries([objectName]);

    history.goBack();
  });

  const forceAppRefresh = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        // console.log('serviceWorker ready! Preparing unregistration');
        registration.unregister().then(() => {
          // console.log('Unregistration done');
          window.location.href = '/';
          //window.location.reload();
        });
      });
    }
  };

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <form className="detailsForm" onSubmit={onSubmit}>
          <Typography variant="h6" gutterBottom>
            {t('DISPATCH_SETTINGS')}
          </Typography>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('dispatch_settings_name')}
                  autoComplete="off"
                  id="dispatch_settings_name"
                  name="dispatch_settings_name"
                  label={t('NAME')}
                  fullWidth
                  variant="standard"
                  defaultValue={data?.dispatch_settings_name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('dispatch_settings_phone')}
                  autoComplete="off"
                  id="dispatch_settings_phone"
                  name="dispatch_settings_phone"
                  label={t('PHONE')}
                  fullWidth
                  variant="standard"
                  defaultValue={data?.dispatch_settings_phone}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            {t('WORKFORCE')}
          </Typography>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('minimum_work_force')}
                  autoComplete="off"
                  id="minimum_work_force"
                  name="minimum_work_force"
                  label={t('MIN_REQUIEREMENT')}
                  fullWidth
                  variant="standard"
                  defaultValue={data?.minimum_work_force}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            {t('EXTERNAL_API')}
          </Typography>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('google_map_api')}
                  autoComplete="off"
                  id="google_map_api"
                  name="google_map_api"
                  label={t('GOOGLE_MAP_API')}
                  fullWidth
                  variant="standard"
                  defaultValue={data?.google_map_api}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Stack direction="row" spacing={1}>
                  <Button type="submit" variant="contained">
                    {t('SAVE')}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Paper sx={{ mt: 1, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t('SMS_QUOTA')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SmsQuota />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ mt: 1, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                forceAppRefresh();
              }}
            >
              {t('FORCE_REFRECH')}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ mt: 1, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{
                textAlign: 'center',
              }}
            >
              © {new Date().getFullYear()} Alarmo {localConfig.version} MADE WITH <FavoriteIcon fontSize="small" /> in SWITZERLAND
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
