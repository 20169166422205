import React from 'react';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import { useUIContext } from '../../contexts/ui/ui.context';
import GroupIcon from '@mui/icons-material/Group';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function LineSeparator({ name }) {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          textAlign: 'center',
          color: 'primary.contrastText',
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom component="div">
          {name}
        </Typography>
      </Box>
    </Grid>
  );
}

function Line({ separatorName, group }) {
  const history = useHistory();

  return (
    <>
      {separatorName !== '' ? <LineSeparator name={separatorName} /> : null}
      <Grid item xs={12}>
        <Button
          icon={GroupIcon}
          iconColor={Colors.button_default_icon}
          bgColor={Colors.button_default}
          onClick={() => history.push(`/user/group/${group.group_id}`)}
        >
          {group.group_name}
        </Button>
      </Grid>
    </>
  );
}

export default function Groups() {
  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('GROUPS'));
  }, []);

  const query = () => getAllData('group');
  const { data, error, isLoading, isError } = useQuery('groups', query);

  if (isLoading) {
    return <Loader />;
  }

  let separatorName;
  let prevGroupType;
  return (
    <Grid container spacing={2}>
      {data.map((group) => {
        separatorName = '';
        if (prevGroupType !== group.group_type_name) {
          separatorName = group.group_type_name;
        }
        prevGroupType = group.group_type_name;
        return <Line key={group.group_id} separatorName={separatorName} group={group} />;
      })}
    </Grid>
  );
}
