import create from 'zustand';
const useStore = create((set, get) => {
  const groupsData = [];
  const membersData = [];
  const selectedGroups = new Map();
  const selectedPerons = new Map();
  const setGroupsData = (data) => {
    set({ groupsData: data });
  };
  const setMembersData = (data) => {
    set({ membersData: data });
  };
  const cancelSelection = () => {
    setSelectedGroups(new Map());
    setSelectedPerons(new Map());
    setSelectionChanged();
  };

  const setSelectionChanged = () =>
    set((state) => {
      let newSelectionChanged = state.selectionChanged;
      newSelectionChanged++;
      return { selectionChanged: newSelectionChanged };
    });

  const setSelectedGroups = (data) => {
    setSelectionChanged();
    return set({ selectedGroups: data });
  };

  const setSelectedPerons = (data) => {
    setSelectionChanged();
    return set({ selectedPerons: data });
  };

  const getSelectedMembers = () => {
    const mySet = new Set();
    for (const member of get().membersData) {
      if (!member.alarm_id) {
        for (const groupId of member.groups_ids) {
          if (get().selectedGroups.has(groupId)) {
            if (get().selectedGroups.get(groupId)) {
              mySet.add(member.member_id);
            }
          }
        }
      }
    }
    for (const person of get().selectedPerons) {
      if (person[1]) {
        mySet.add(person[0]);
      } else {
        mySet.delete(person[0]);
      }
    }
    return mySet;
  };

  return {
    selectedMembersId: new Set(),
    selectedGroups: selectedGroups,
    selectedPerons: selectedPerons,
    selectionChanged: 0,
    groupsData: groupsData,
    membersData: membersData,
    setGroupsData: setGroupsData,
    setMembersData: setMembersData,
    getSelectedMembers: getSelectedMembers,
    setSelectedGroups: setSelectedGroups,
    setSelectedPerons: setSelectedPerons,
    setSelectionChanged: setSelectionChanged,
    cancelSelection: cancelSelection,
  };
});

export default useStore;
