import React from 'react';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import { useUIContext } from '../../contexts/ui/ui.context';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import SpecializationWorkForce from './specializationWorkForce';
import SpecializationAvailability from './specializationAvailability';

export default function Specialization() {
  let { id: specId } = useParams();
  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();

  const [viewType, setViewType] = React.useState('AVAILABILITY');

  const getQueryFn = async () => await get(`specialization/${specId}`);
  const { isLoading } = useQuery(['specialization', specId], getQueryFn, {
    onSuccess: (data) => {
      setAppBarTitle(`${t('SPECIALIZATION')}: ${data.specialization_name}`);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setViewType(newType);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ToggleButtonGroup color="primary" value={viewType} exclusive fullWidth onChange={handleChange}>
          <ToggleButton value="AVAILABILITY">{t('AVAILABILITY')}</ToggleButton>
          <ToggleButton value="WORKFORCE">{t('WORKFORCE')}</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {viewType === 'WORKFORCE' ? <SpecializationWorkForce noToggleButton specId={specId} /> : <SpecializationAvailability specId={specId} />}
      </Grid>
    </Grid>
  );
}
