import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function LinksList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'link_id', headerName: 'ID', hide: true },
    {
      field: 'link_name',
      headerName: t('NAME'),
      width: '*',
      flex: 1,
      editable: true,
    },
    {
      field: 'link_type',
      headerName: t('LINK_TYPE'),
      width: '*',
      flex: 1,
      editable: true,
      valueGetter: function (value) {
        return t(value);
      },
    },
    {
      field: 'link_order',
      headerName: t('LINK_ORDER'),
      width: '*',
      flex: 1,
      editable: true,
    },
  ];

  return <GenericList objectName={'link'} columns={columns} />;
}
