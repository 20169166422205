import React from 'react';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import VehicleOnSiteIcon from 'src/icons/v_on_site';
import VehicleOnWayIcon from 'src/icons/v_on_way';

export default function MaterialButton({ material, size = 'large' }) {
  let Icon = EventAvailableIcon;
  let ExtraIcon = null;
  let color = 'vehicle_available';
  switch (material.material_status) {
    case 'ENGAGED':
      Icon = AddAlertIcon;
      color = 'vehicle_engaged';
      break;
    case 'ON_SITE':
      Icon = AddAlertIcon;
      color = 'vehicle_on_site';
      ExtraIcon = VehicleOnSiteIcon;
      break;
    case 'ON_WAY':
      Icon = AddAlertIcon;
      color = 'vehicle_on_way';
      ExtraIcon = VehicleOnWayIcon;
      break;
    case 'OUT_OF_ORDER':
      Icon = AddAlertIcon;
      color = 'vehicle_out_of_order';
      break;
    case 'NOT_AVAILABLE':
      color = 'vehicle_not_available';
      break;

    default:
      color = 'neutral';
      break;
  }

  return (
    <Grid container spacing={1}>
      {ExtraIcon && size === 'large' ? (
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <ExtraIcon />
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={ExtraIcon && size === 'large' ? 10 : 12}>
        <ButtonGroup size={size} sx={{ height: '100%' }} color={color} fullWidth variant="contained" aria-label="split button">
          <Button
            sx={{ height: '100%', p: 0.5 }}
            variant="contained"
            // startIcon={<VtractLogo style={{ height: '40px', width: '46px', color: 'blue' }} />}
            size={size}
            color={color}
          >
            {material.material_code} / {material.material_name}
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
