import React from 'react';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import useStore from './reinforcement.store';

const SelectionSummary = styled(Paper)(() => ({
  textAlign: 'center',
  height: 60,
  lineHeight: '60px',
}));

export default function ReinforcementSelectionSummary({ selectedMember, total }) {
  const selectionChanged = useStore((state) => state.selectionChanged); //Need to keep this line to trigger the state change on selected Member

  const getSelectedMembers = useStore((state) => state.getSelectedMembers);
  console.log(getSelectedMembers());
  return (
    <SelectionSummary>
      {getSelectedMembers().size} / {total}
    </SelectionSummary>
  );
}
