import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { post } from 'src/api/api';
import { useQuery, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { get } from 'src/api/api';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function VehicleStatus({ vehicle }) {
  console.log(vehicle);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const vehicleClickHandler = async (newStatus) => {
    await post(`vehicle/${vehicle.vehicle_id}/currentIntervention`, { newStatus });
    queryClient.invalidateQueries('vehicle/withState');
    queryClient.invalidateQueries('material/withState');
  };

  const [currentStatus, setCurrentStatus] = React.useState();
  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading } = useQuery(['currentUserStatus'], getQueryFn, {
    onSuccess: (data) => {
      setCurrentStatus(data);
    },
  });

  let color = '';
  switch (vehicle.vehicle_status) {
    case 'ENGAGED':
      color = 'vehicle_engaged';
      break;
    case 'ON_SITE':
      color = 'vehicle_on_site';
      break;
    case 'ON_WAY':
      color = 'vehicle_on_way';
      break;
    case 'OUT_OF_ORDER':
      color = 'vehicle_out_of_order';
      break;
    case 'NOT_AVAILABLE':
      color = 'vehicle_not_available';
      break;
    case 'TRAINING':
      color = 'vehicle_training';
      break;

    default:
      color = '';
      break;
  }

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '0px',
          paddingTop: '10px',
        }}
      >
        <Box>
          <Box sx={{ color: `${color}.main` }}>
            <Typography color={color} variant="subtitle2" gutterBottom>
              {vehicle.vehicle_code} / {vehicle.vehicle_name}
            </Typography>
          </Box>
          {vehicle?.firstname ? (
            <>
              <a href={`tel://${vehicle.phone}`}>
                <Button fullWidth variant="contained" startIcon={<LocalPhoneIcon />} color={'neutral'}>
                  {vehicle.firstname} {vehicle.lastname}
                </Button>
              </a>
            </>
          ) : null}
          <hr />
        </Box>
      </Box>
      {currentStatus?.isInAlarm ? (
        <>
          {vehicle.vehicle_status != 'ON_WAY' ? (
            <Button
              fullWidth
              variant="contained"
              size="large"
              color={'vehicle_on_way'}
              onClick={() => {
                vehicleClickHandler('ON_WAY');
              }}
            >
              {t('ON_WAY')}
            </Button>
          ) : null}

          {vehicle.vehicle_status != 'ON_SITE' ? (
            <Button
              fullWidth
              variant="contained"
              size="large"
              color={'vehicle_on_site'}
              onClick={() => {
                vehicleClickHandler('ON_SITE');
              }}
            >
              {t('ON_SITE')}
            </Button>
          ) : null}

          {vehicle.vehicle_status != 'OUT_OF_ORDER' ? (
            <Button
              fullWidth
              variant="contained"
              size="large"
              color={'vehicle_out_of_order'}
              onClick={() => {
                vehicleClickHandler('OUT_OF_ORDER');
              }}
            >
              {t('VEHICLE_OUT_OF_ORDER')}
            </Button>
          ) : null}
        </>
      ) : null}
      {vehicle.vehicle_status != null ? (
        <Button
          fullWidth
          variant="contained"
          size="large"
          color={'neutral'}
          onClick={() => {
            vehicleClickHandler('AVAILABLE');
          }}
        >
          {t('VEHICLE_AVAILABLE')}
        </Button>
      ) : null}

      {vehicle.vehicle_status != 'TRAINING' ? (
        <Button
          fullWidth
          variant="contained"
          size="large"
          color={'vehicle_training'}
          onClick={() => {
            vehicleClickHandler('TRAINING');
          }}
        >
          {t('VEHICLE_TRAINING_DRIVING')}
        </Button>
      ) : null}

      {vehicle.vehicle_status != 'NOT_AVAILABLE' ? (
        <Button
          fullWidth
          variant="contained"
          size="large"
          color={'vehicle_not_available'}
          onClick={() => {
            vehicleClickHandler('NOT_AVAILABLE');
          }}
        >
          {t('VEHICLE_NOT_AVAILABLE')}
        </Button>
      ) : null}
    </Stack>
  );
}
