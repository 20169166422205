import React from 'react';
import { styled } from '@mui/system';
import { Colors } from 'src/styles/variables';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import TableauBloc from '../tableau-bloc/tableau-bloc';
import { Utils } from 'src/services/utils';

const BlocName = styled('h1')({
  color: Colors.section,
  textAlign: 'center',
});

export default function BlocOnDuty({ title, datas }) {
  const getQueryFn = async () => await get(`board/raw`);
  const { data, isLoading } = useQuery(['board/raw'], getQueryFn);
  if (isLoading) {
    return <Loader />;
  }
  const members = [];
  for (const key in datas) {
    if (Object.hasOwnProperty.call(datas, key)) {
      const element = datas[key];
      for (const member of element.members) {
        if (member.asignments[0] !== null) {
          members.push(member);
        }
      }
    }
  }

  if (members.length === 0) return null;

  return <TableauBloc title={title} displayAvailability={'ALL'} blocData={Utils.sortByRankAndName(members)} titleOnlyTotal />;
}
