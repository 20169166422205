import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Utils } from 'src/services/utils';

export default function InterventionLogCategorySelector({ defaultId, onChange, label = '', inputRef }) {
  const [groupList, setGroupList] = useState();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const getQueryFn = async ({ queryKey }) => await get('intervention-log-category/list', queryKey[1]);
  const { data, isLoading } = useQuery(['intervention-log-category/search'], getQueryFn, {
    onSuccess: (data) => {
      if ((data[0].incident_log_preset_category_id ?? null) !== null) {
        data.unshift({
          incident_log_preset_category_id: null,
          name: '',
          category_order: 0,
        });
      }

      setGroupList(data);
      if (defaultId) {
        setValue(data.find((e) => e.incident_log_preset_category_id === defaultId));
      }
    },
  });

  useEffect(() => {
    if (defaultId && groupList) {
      setValue(groupList.find((e) => e.incident_log_preset_category_id === defaultId));
    } else {
      setValue(null);
    }
  }, [defaultId, groupList]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <form autoComplete="off">
        <Autocomplete
          noOptionsText={t('NO_OPTION')}
          fullWidth
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={data}
          getOptionLabel={(option) => (option.incident_log_preset_category_id === null ? '⠀' : `${option.name}`)}
          renderInput={(params) => <TextField variant="standard" fullWidth autoComplete="off" {...params} label={label} inputRef={inputRef} />}
        />
      </form>
    </div>
  );
}
