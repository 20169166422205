import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';

import { styled } from '@mui/material/styles';
import Loader from 'src/shared/defaultLoader';
import { useHistory } from 'react-router-dom';

const BigButton = styled(Button)(() => ({
  // transform: 'scale(2)',
  width: '90%',
  height: '100px',
}));

export default function UserBoardList() {
  const { t } = useTranslation();
  const history = useHistory();

  const query = () => getAllData('display_board');
  const { data, error, isLoading, isError } = useQuery('display_board', query);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={1}>
      {data.map((board) => (
        <Grid item xs={12} key={board.display_board_id}>
          <Button bgColor={Colors.button_default} onClick={() => history.push(`board/${board.display_board_id}`)}>
            {board.board_name}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
