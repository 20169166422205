import React, { useState, useEffect } from 'react';
// import Button from '../../components/button/button';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { DateUtiles } from 'src/services/dateUtiles';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { post } from 'src/api/api';
import { useQueryClient } from 'react-query';

export default function AvailabilityMenuButton({ baseDate }) {
  const monday = DateUtiles.getMonday(baseDate);
  const sunday = DateUtiles.getSunday(baseDate);
  const { t } = useTranslation();
  // console.log(monday);
  // const [hasCopyActive, setHasCopyActive] = React.useState(false);
  const [copyBaseDate, setCopyBaseDate] = React.useState(null);
  const [feedBack, setFeedBack] = React.useState('');

  const queryClient = useQueryClient();

  const pastClickHandler = async (type) => {
    const copyBaseDateEnd = DateUtiles.getSunday(copyBaseDate);
    const data = {
      sourceFrom: copyBaseDate,
      sourceTo: copyBaseDateEnd,
      destinationFrom: monday,
      destinationTo: sunday,
      type: type,
    };
    await post('availability/copy', data);
    queryClient.invalidateQueries(['currentUserWeeklyAvailability/' + monday]);
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu4">
      {(popupState) => (
        <React.Fragment>
          <Badge sx={{ width: '100%' }} variant="dot" color="primary" badgeContent={copyBaseDate ? 1 : 0}>
            <Button fullWidth variant="contained" {...bindTrigger(popupState)} size="large" color="neutral">
              <MenuIcon />
            </Button>
          </Badge>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                // setHasCopyActive(true);
                setFeedBack(t('CURRENT_WEEK_COPIED'));
                setCopyBaseDate(monday);
                popupState.close();
              }}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('COPY')}</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={!copyBaseDate}
              onClick={() => {
                popupState.close();
                pastClickHandler('CURRENT');
              }}
            >
              <ListItemIcon>
                <ContentPaste fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('PAST_CURRENT_WEEK')}</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={!copyBaseDate}
              onClick={() => {
                popupState.close();
                pastClickHandler('END_OF_YEAR');
              }}
            >
              <ListItemIcon>
                <ContentPaste fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('PAST_UNTIL_END_OF_YEAR')}</ListItemText>
            </MenuItem>
          </Menu>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={feedBack !== ''}
            autoHideDuration={3000}
            onClose={() => {
              setFeedBack('');
            }}
          >
            <Alert
              onClose={() => {
                setFeedBack('');
              }}
              severity={'success'}
              sx={{ width: '100%' }}
            >
              {t(feedBack)}
            </Alert>
          </Snackbar>
        </React.Fragment>
      )}
    </PopupState>
  );
}
