import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { updateData } from 'src/api/api';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import { LANGUAGES } from 'src/constants/constants';
import MenuItem from '@mui/material/MenuItem';
import { useUIContext } from '../../contexts/ui/ui.context';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AuthenticationService } from 'src/services/auth';
import Alert from '@mui/material/Alert';

export default function UserParams() {
  const [error, setError] = useState('');

  const { register, handleSubmit, control } = useForm();
  const objectName = 'params/currentUser';
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const localConfig = queryClient.getQueryData(['local/config']);
  const history = useHistory();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('PARAMETERS'));
  }, []);

  const updateQueryFn = async ({ ...data }) => await updateData(objectName, { ...data });

  const data = queryClient.getQueryData([objectName]);

  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([objectName], data);
    },
  });

  if (data === undefined) {
    return null;
  }

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsyncUpdate({ ...data });
    queryClient.invalidateQueries([objectName]);
    queryClient.refetchQueries([objectName]);

    history.goBack();
  });

  const onPasswordSubmit = async (event) => {
    setError('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('password') === data.get('password_confirm')) {
      const result = await AuthenticationService.updatePassword(data.get('password'));
      if (result) {
        history.push('/');
      } else {
        setError('PASSWORD_RESET_ERROR');
      }
    } else {
      setError('PASSWORD_MISSMATCH');
    }
  };

  const forceAppRefresh = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        // console.log('serviceWorker ready! Preparing unregistration');
        registration.unregister().then(() => {
          // console.log('Unregistration done');
          window.location.href = '/';
          //window.location.reload();
        });
      });
    }
  };
  return (
    <React.Fragment>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t('GLOBAL_PARAMETERS')}
        </Typography>
        <form className="detailsForm" onSubmit={onSubmit}>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12}>
              <ReactHookFormSelect
                register={register}
                id="lang"
                name="lang"
                label={t('LANGUAGE')}
                control={control}
                defaultValue={data?.lang}
                variant="standard"
              >
                {LANGUAGES.map((type) => {
                  return (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormSelect
                register={register}
                id="availability_type_input"
                name="availability_type_input"
                label={t('AVAILABILITY_TYPE_INPUT')}
                control={control}
                defaultValue={data?.availability_type_input ?? 'DETAILED'}
                variant="standard"
              >
                <MenuItem value="DETAILED">{t('DETAILED')}</MenuItem>
                <MenuItem value="QUICK">{t('QUICK')}</MenuItem>
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormSelect
                register={register}
                id="availability_privacy_mode"
                name="availability_privacy_mode"
                label={t('AVAILABILITY_PRIVACY_MODE')}
                control={control}
                defaultValue={data?.availability_privacy_mode ?? 'FULL'}
                variant="standard"
              >
                <MenuItem value="FULL">{t('AVAILABILITY_PRIVACY_MODE_FULL')}</MenuItem>
                <MenuItem value="INSTANT">{t('AVAILABILITY_PRIVACY_MODE_INSTANT')}</MenuItem>
              </ReactHookFormSelect>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            {t('TIMING_DEFAULT_VALUES')}
          </Typography>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('default_time_days')}
                  autoComplete="off"
                  id="default_time_days"
                  name="default_time_days"
                  label={t('DAYS')}
                  fullWidth
                  variant="standard"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  defaultValue={data.default_time_days}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('default_time_hours')}
                  autoComplete="off"
                  id="default_time_hours"
                  name="default_time_hours"
                  label={t('HOURS')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.default_time_hours}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('default_time_minutes')}
                  autoComplete="off"
                  id="default_time_minutes"
                  name="default_time_minutes"
                  label={t('MINUTES')}
                  fullWidth
                  variant="standard"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  defaultValue={data.default_time_minutes}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            {t('NOTIFICATION')}
          </Typography>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('personal_notification_param')}
                  autoComplete="off"
                  id="personal_notification_param"
                  name="personal_notification_param"
                  label={t('PERSONAL_NOTIFICATION_PARAM')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.personal_notification_param}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('professional_notification_param')}
                  autoComplete="off"
                  id="professional_notification_param"
                  name="professional_notification_param"
                  label={t('PROFESSIONAL_NOTIFICATION_PARAM')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.professional_notification_param}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Stack direction="row" spacing={1}>
                  <Button type="submit" variant="contained">
                    {t('SAVE')}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Paper sx={{ mt: 1, p: 3 }}>
        <form className="detailsForm" onSubmit={onPasswordSubmit}>
          <Typography variant="h6" gutterBottom>
            {t('PASSWORD')}
          </Typography>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('password')}
                  autoComplete="off"
                  id="password"
                  name="password"
                  label={t('PASSWORD')}
                  type="password"
                  fullWidth
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('password_confirm')}
                  autoComplete="off"
                  id="password_confirm"
                  name="password_confirm"
                  label={t('PASSWORD_CONFIRM')}
                  type="password"
                  fullWidth
                  variant="standard"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {error !== '' ? (
              <Grid item xs={12}>
                <Alert severity="error">{t(error)}</Alert>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Stack direction="row" spacing={1}>
                  <Button type="submit" variant="contained">
                    {t('RESET_PASSWORD')}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Paper sx={{ mt: 1, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                forceAppRefresh();
              }}
            >
              {t('FORCE_REFRECH')}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ mt: 1, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{
                textAlign: 'center',
              }}
            >
              © {new Date().getFullYear()} Alarmo {localConfig.version} MADE WITH <FavoriteIcon fontSize="small" /> in SWITZERLAND
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
