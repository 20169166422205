import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useUIContext } from '../../contexts/ui/ui.context';

import { useQuery } from 'react-query';
import { getAllData, get } from 'src/api/api';

import Loader from 'src/shared/defaultLoader';
import Vehicle from './vehicle';
import Materials from './materials';

export default function Appartus() {
  const groupQuery = () => getAllData('group');
  const { data: groupData, isLoading: groupIsLoading } = useQuery('groups', groupQuery);

  const getQueryFn = async () => await get(`board/raw`, { sort_by: 'rank' });
  const { data: membersData, isLoading: membersIsLoading } = useQuery(['reinforcementsPeople'], getQueryFn);

  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('VEHICLES_AND_MATERIALS'));
  }, []);

  const [view, setView] = React.useState('VEHICLES');

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setView(newType);
    }
  };

  // if (groupIsLoading || membersIsLoading) {
  //   return <Loader />;
  // }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ToggleButtonGroup color="primary" value={view} exclusive fullWidth onChange={handleChange}>
            <ToggleButton value="VEHICLES">{t('VEHICLES')}</ToggleButton>
            <ToggleButton value="MATERIALS">{t('MATERIALS')}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12}>
          {view === 'VEHICLES' ? <Vehicle /> : null}
          {view === 'MATERIALS' ? <Materials /> : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
