import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Colors } from 'src/styles/variables';
import Box from '@mui/material/Box';

export default function MemberAgendaLine({ day, dayIndex, type, highlight }) {
  return (
    <>
      <TableCell align="right" sx={{ minWidth: 5, height: '1em', p: 0, borderColor: '#aaa' }}>
        <Box
          sx={{
            bgcolor: highlight ? Colors[day.availabilities[0]?.availabilities[0].title].dark : Colors[day.availabilities[0]?.availabilities[0].title].light,
            height: '50%',
            width: '100%',
            borderRight: '1px solid #ccc',
          }}
        />
        <Box
          sx={{
            bgcolor: highlight ? Colors[day.availabilities[1]?.availabilities[0].title].dark : Colors[day.availabilities[1]?.availabilities[0].title].light,
            height: '50%',
            width: '100%',
            borderRight: '1px solid #ccc',
            borderTop: '1px solid #ccc',
          }}
        />
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 5, height: '1em', p: 0, borderColor: '#aaa' }}>
        <Box
          sx={{
            bgcolor: highlight ? Colors[day.availabilities[2]?.availabilities[0].title].dark : Colors[day.availabilities[2]?.availabilities[0].title].light,
            height: '100%',
            width: '100%',
            borderRight: '1px solid #888',
          }}
        />
      </TableCell>
    </>
  );
}

/* <TableCell key={dayIndex + '-' + timeIndex} align="right" sx={{ minWidth: 5, height: '1em', p: 0, borderColor: '#aaa' }}>
          {a.availabilities.map((extra_a, index) => {
            let nbOfSubRecord = a.availabilities.length;
            if (nbOfSubRecord === 0) {
              nbOfSubRecord = 1;
            }

            let bgColor = Colors[extra_a.title].light;

            const r_height = 100 / nbOfSubRecord;
            let borderColod = '#ccc';
            if ((timeIndex + 1) % 3 === 0) {
              borderColod = '#888';
            }
            return <Box key={index} sx={{ bgcolor: bgColor, height: r_height + '%', width: '100%', borderRight: '1px solid ' + borderColod }} />;
          })}
        </TableCell> */
