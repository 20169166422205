import React from 'react';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';

export default function SmsQuota() {
  const getQuery = async () => await get('sms/quota');
  const { data, isLoading } = useQuery(['sms/quota'], getQuery, {
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return <Loader />;
  }
  return <div>{data}</div>;
}
