import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Colors } from 'src/styles/variables';
import WorkforceSummary from 'src/shared/workforceSummary';

const BlocName = styled('h1')({
  color: Colors.section,
  textAlign: 'center',
});
export default function TableauWorkforce({ title }) {
  return (
    <Box>
      {title ? <BlocName>{title}</BlocName> : null}
      <WorkforceSummary />
    </Box>
  );
}
