import React from 'react';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import { useUIContext } from '../../contexts/ui/ui.context';
import { useHistory } from 'react-router-dom';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

function Line({ group }) {
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Button
        icon={ContactPhoneIcon}
        iconColor={Colors.button_default_icon}
        bgColor={Colors.button_default}
        onClick={() => history.push(`/user/phone_books/group/${group.phone_directory_group_id}`)}
      >
        {group.phone_directory_group_name}
      </Button>
    </Grid>
  );
}

export default function DirectoriesGroups() {
  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('PHONE_BOOKS'));
  }, []);

  const query = () => getAllData('directory/group');
  const { data, isLoading } = useQuery('directory/group', query);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      {data.map((group) => {
        return <Line key={group.phone_directory_group_id} group={group} />;
      })}
    </Grid>
  );
}
