import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DateUtiles } from 'src/services/dateUtiles';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import Box from '@mui/material/Box';

export default function Agenda({ baseDate, type, memberId = null }) {
  const { t } = useTranslation();

  const monday = DateUtiles.getMonday(baseDate);
  const sunday = DateUtiles.getSunday(baseDate);

  let url = '';
  let cacheKey = '';
  switch (type) {
    case 'AVAILABILITY':
      url = 'availability/calendar';
      cacheKey = 'currentUserWeeklyAvailability/';
      break;
    case 'FUNCTION':
      url = 'function/calendar';
      cacheKey = 'currentUserWeeklyFunction/';
      break;
    default:
      break;
  }
  if (memberId) {
    url += '/' + memberId;
    cacheKey += memberId;
  }

  const getQueryFn = async ({ queryKey }) => await get(url, { start: monday, end: sunday });
  const { data, isLoading } = useQuery([cacheKey + monday], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  let weekDays = [];

  if (isLoading) {
    return <Loader />;
  }
  let defaultLineMode = 'NOT_AVAILABLE';
  if (data.length > 0) {
    if (data[0].title === 'PRIVATE') {
      defaultLineMode = 'PRIVATE';
    }
  }

  const getRecordByDate = (date) => {
    let availabilities = [];
    if (data) {
      for (const availability of data) {
        const start = new Date(availability.start);
        const end = new Date(availability.end);
        if (start <= date && end >= date) {
          availabilities.push(availability);
        }
      }
    }
    if (availabilities.length === 0) {
      availabilities.push({
        id: 0,
        title: defaultLineMode,
        start: null,
        end: null,
        color: null,
        textColor: 'black',
      });
    }
    return availabilities;
  };

  for (let index = 0; index < 7; index++) {
    const day = DateUtiles.getMonday(baseDate);
    day.setDate(day.getDate() + index);
    let dayAbbr = '';
    switch (day.getDay()) {
      case 1:
        dayAbbr = 'MONDAY_ABBR';
        break;
      case 2:
        dayAbbr = 'TUESDAY_ABBR';
        break;
      case 3:
        dayAbbr = 'WEDNESDAY_ABBR';
        break;
      case 4:
        dayAbbr = 'THUSDAY_ABBR';
        break;
      case 5:
        dayAbbr = 'FRIDAY_ABBR';
        break;
      case 6:
        dayAbbr = 'SATURDAY_ABBR';
        break;
      case 0:
        dayAbbr = 'SUNDAY_ABBR';
        break;
      default:
        break;
    }
    const dayData = {
      id: index,
      date: day,
      name: t(dayAbbr) + ' ' + day.getDate(),
      availabilities: [],
    };
    for (let j = 0; j < 48; j++) {
      const eDate = DateUtiles.addMinutes(monday, 30 * j + 1440 * index);
      const records = getRecordByDate(eDate);
      const m = { id: j, date: eDate, availabilities: records };
      dayData.availabilities.push(m);
    }
    weekDays.push(dayData);
  }
  // console.log(weekDays);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 50, width: 50, p: 0 }}></TableCell>
            <TableCell sx={{ minWidth: 2, p: 0 }} align="left">
              00:00
            </TableCell>

            <TableCell sx={{ minWidth: 2, p: 0 }} align="left">
              06:00
            </TableCell>

            <TableCell sx={{ minWidth: 2, p: 0 }} align="left">
              12:00
            </TableCell>

            <TableCell sx={{ minWidth: 2, p: 0 }} align="left">
              18:00
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>
          {weekDays.map((day) => (
            <TableRow key={day.id}>
              <TableCell sx={{ minWidth: 50, width: 50, p: '5px' }}>{day.name}</TableCell>
              {day.availabilities.map((a) => {
                return (
                  <TableCell key={a.id} align="right" sx={{ minWidth: 5, height: '1em', p: 0, borderColor: '#aaa' }}>
                    {a.availabilities.map((extra_a, index) => {
                      let nbOfSubRecord = a.availabilities.length;
                      if (nbOfSubRecord === 0) {
                        nbOfSubRecord = 1;
                      }

                      let bgColor = '';
                      switch (type) {
                        case 'AVAILABILITY':
                          bgColor = Colors[extra_a.title].light;
                          break;
                        case 'FUNCTION':
                          bgColor = extra_a.color;
                          break;
                        default:
                          break;
                      }

                      const r_height = 100 / nbOfSubRecord;
                      return <Box key={index} sx={{ bgcolor: bgColor, height: r_height + '%', width: '100%' }} />;
                    })}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
