import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { useClipboard } from '@mantine/hooks';
import { B2_BASE_URL } from 'src/constants/constants';
import { useQueryClient } from 'react-query';

export default function MapFilesList() {
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);
  const clipboard = useClipboard({ timeout: 500 });
  const { t } = useTranslation();

  function RowMenuCell(props) {
    const handleCopyClick = async (event) => {
      event.stopPropagation();
      const row = props.row;
      // console.log(row);
      const mapFileUrl = JSON.parse(row.map_file_url);

      switch (row.map_file_type) {
        case 'IMAGE':
          clipboard.copy(`
            <div>
              <div style="font-family: Roboto,Arial,sans-serif; font-size: small">
                  <div style="font-weight: 500; font-size: medium; margin-bottom: 0em">${row.map_file_name}</div>
                  <div><br><a href="${globalParam.files_server || B2_BASE_URL}${mapFileUrl.fileName}" target="_blank" tabindex="0"><img src="${
            globalParam.files_server || B2_BASE_URL
          }${mapFileUrl.fileName}" height="350" width="auto"></a></div>
              </div>
            </div>
          `);
          break;

        case 'PDF':
          clipboard.copy(`
            <div>
              <div style="font-family: Roboto,Arial,sans-serif; font-size: small">
                  <div style="font-weight: 500; font-size: medium; margin-bottom: 0em">${row.map_file_name}</div>
                  <div><br><a href="${globalParam.files_server || B2_BASE_URL}${
            mapFileUrl.fileName
          }" target="_blank" tabindex="0"><img src="https://lh5.googleusercontent.com/proxy/WvirZUpC8f-BlVJiH-BOms_r0E9k0Twj0kVxXmm3Zbh3x0b4twg-sAIKLaHaq1NMjcBm0-SASj3SBSGk8XUmxcageWqoIzwn93OLANDToyH8luQFHJsfH3IiYsqe2Vjk-ZhSY3d6CjnMDSk2ExJcEHKd3D0N9CZRu6mpBaJhCIMP_kiqlRh1vcPB_llOdgzz9kosGd7J3rrZyAAOF30ZyEWqc7BXA5ICi3MaQDmw=s0-d" height="200" width="auto"></a></div>
              </div>
            </div>
          `);
          break;

        default:
          break;
      }
    };

    return (
      // <div className={classes.root}>
      <div>
        <IconButton color="inherit" size="small" aria-label="delete" onClick={handleCopyClick}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }

  const columns = [
    { field: 'map_file_id', headerName: 'ID', hide: true },
    {
      field: 'map_file_name',
      headerName: t('NAME'),
      width: '150',
      flex: 1,
      editable: true,
    },
    {
      field: 'map_file_type',
      headerName: t('FILE_TYPE'),
      width: '150',
      flex: 1,
      editable: true,
      valueGetter: function (value) {
        return t(value);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: RowMenuCell,
    },
  ];

  return <GenericList objectName={'map-files'} columns={columns} rowId={'map_file_id'} />;
}
