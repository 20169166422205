import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function AlarmTypesList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'alarm_type_id', headerName: 'ID', hide: true },
    {
      field: 'alarm_code',
      headerName: t('ALARM_CODE'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'alarm_description',
      headerName: t('ALARM_DESCRIPTION'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'alarm_order',
      headerName: t('SORT_ORDER'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={'alarm/types'} columns={columns} rowId={'alarm_type_id'} />;
}
