import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData, getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import MenuItem from '@mui/material/MenuItem';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import { MATERIAL_TYPE } from 'src/constants/constants';

export default function Material() {
  const { register, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('material', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['material', { id }], getQueryFn);

  const inputRef = React.useRef();

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'material'} handleSubmit={handleSubmit} data={data} isDisabledManagement={true}>
      <Grid item xs={12} sm={6}>
        <ReactHookFormSelect
          required
          register={register}
          id="material_type"
          name="material_type"
          label={t('MATERIAL_TYPE')}
          control={control}
          defaultValue={data.material_type}
          variant="standard"
        >
          {MATERIAL_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('material_code')}
            required
            autoComplete="off"
            id="material_code"
            name="material_code"
            label={t('MATERIAL_CODE')}
            fullWidth
            variant="standard"
            defaultValue={data.material_code}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('material_name')}
            required
            autoComplete="off"
            id="material_name"
            name="material_name"
            label={t('MATERIAL_NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.material_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('material_order')}
            required
            id="material_order"
            name="material_order"
            label={t('SORT_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.material_order}
          />
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
