import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './pages/home/home';
import Availability from './pages/availability/availability';
import QuickStatusChange from './pages/quickStatusChange/quickStatusChange';
import AlarmReceipt from './pages/alarmReceipt/alarmReceipt';
import Links from './pages/links/links';
import AlarmList from '../shared/pages/alarmList/alarmList';
import Alarm from '../shared/pages/alarm/alarm';
import Calendar from './pages/calendar/calendar';
import UserPlanning from './pages/userPlanning/userPlanning';
import AllMember from './pages/member/allMember';
import MemberCalendar from './pages/member/memberCalendar';
import Assignation from './pages/assignation/assignation';
import Apps from './pages/apps/apps';
import AllPlanning from './pages/allPlanning/allPlanning';
import Container from '@mui/material/Container';
import UserBoard from './pages/userBoard/userBoard';
import UserBoardList from './pages/userBoardList/userBoardList';
import Groups from './pages/groups/groups';
import Group from './pages/group/group';
import Specializations from './pages/specialization/specializations';
import Specialization from './pages/specialization/specialization';
import MyDuty from './pages/myDuty/myDuty';
import UserParams from './pages/userParams/userParams';
import Reinforcements from './pages/reinforcements/reinforcements';
import ReinforcementMessage from './pages/reinforcements/reinforcementMessage';
import DirectoriesGroups from './pages/directories/directoriesGroups';
import DirectoriesList from './pages/directories/directoriesList';
import DirectoryContact from './pages/directories/directoryContact';
import Maps from './pages/maps/maps';
import ChangeLog from 'src/shared/changeLog';
import Appartus from './pages/appartus/appartus';
import IncidentsList from './pages/incidents/list/incidentsList';
import IncidentDetail from './pages/incidents/detail/incidentDetail';
import IncidentCardEdit from './pages/incidents/card/incidentCardEdit';
import InterventionLog from './pages/interventionLog/interventionLog';
import InterventionLogEntry from './pages/interventionLog/entry/interventionLogEntry';
import CurrentInterventionLog from './pages/interventionLog/currentInterventionLog';
import OneInterventionLog from './pages/interventionLog/oneInterventionLog';
import InterventionLogQuickEntry from './pages/interventionLog/quickEntry/interventionLogQuickEntry';
import IncidentNewIC from './pages/incidentNewIC/incidentNewIC';
import OpenIncidentsList from './pages/incidents/open/openIncidentsList';
import InterventionLogReport from './pages/interventionLog/report/interventionLogReport';

export default function UserContent() {
  let { path } = useRouteMatch();

  const [mainPadding, setMainPadding] = React.useState(1);

  return (
    <Container maxWidth="sm" sx={{ p: '4px' }}>
      <Switch>
        <Route path={`${path}/receipts`}>
          <AlarmReceipt />
        </Route>
        <Route path={`${path}/links`}>
          <Links />
        </Route>
        <Route path={`${path}/group/:id`}>
          <Group />
        </Route>
        <Route path={`${path}/groups`}>
          <Groups />
        </Route>
        <Route path={`${path}/specialization/:id`}>
          <Specialization />
        </Route>
        <Route path={`${path}/specializations`}>
          <Specializations />
        </Route>
        <Route path={`${path}/availability`}>
          <Availability />
        </Route>
        <Route path={`${path}/calendar/assignation/:assignation_id`}>
          <Assignation />
        </Route>
        <Route path={`${path}/my-duty/assignation/:assignation_id`}>
          <Assignation />
        </Route>
        <Route path={`${path}/planning/assignation/:assignation_id`}>
          <Assignation />
        </Route>
        <Route path={`${path}/calendar`}>
          <Calendar />
        </Route>
        <Route path={`${path}/board/:id`}>
          <UserBoard noLogo />
        </Route>
        <Route path={`${path}/board`}>
          <UserBoardList />
        </Route>
        <Route path={`${path}/quickStatusUpdate/:status`}>
          <QuickStatusChange />
        </Route>
        <Route path={`${path}/alarm/:id`}>
          <Alarm />
        </Route>
        <Route path={`${path}/alarms/`}>
          <AlarmList />
        </Route>
        <Route path={`${path}/planning/`}>
          <UserPlanning />
        </Route>
        <Route path={`${path}/all-planning/`}>
          <AllPlanning />
        </Route>
        <Route path={`${path}/member/:id/assignation/:assignation_id`}>
          <Assignation />
        </Route>
        <Route path={`${path}/member/:id`}>
          <MemberCalendar />
        </Route>
        <Route path={`${path}/member/`}>
          <AllMember />
        </Route>
        <Route path={`${path}/apps`}>
          <Apps />
        </Route>
        <Route path={`${path}/my-duty`}>
          <MyDuty />
        </Route>
        <Route path={`${path}/params`}>
          <UserParams />
        </Route>
        <Route path={`${path}/reinforcements/message/:type`}>
          <ReinforcementMessage />
        </Route>
        <Route path={`${path}/reinforcements`}>
          <Reinforcements />
        </Route>
        <Route path={`${path}/phone_books/group/:groupId/phone_books/:directoryId`}>
          <DirectoryContact />
        </Route>
        <Route path={`${path}/phone_books/group/:id`}>
          <DirectoriesList />
        </Route>
        <Route path={`${path}/phone_books`}>
          <DirectoriesGroups />
        </Route>
        <Route path={`${path}/maps`}>
          <Maps />
        </Route>
        <Route path={`${path}/changelog`}>
          <ChangeLog />
        </Route>
        <Route path={`${path}/apparatus`}>
          <Appartus />
        </Route>
        <Route path={`${path}/interventions`}>
          <IncidentsList />
        </Route>
        <Route path={`${path}/open-interventions`}>
          <OpenIncidentsList />
        </Route>
        <Route path={`${path}/incident/:id/edit`}>
          <IncidentCardEdit />
        </Route>
        <Route path={`${path}/incident/:id`}>
          <IncidentDetail />
        </Route>
        <Route path={`${path}/intervention/log`}>
          <CurrentInterventionLog />
        </Route>
        <Route path={`${path}/intervention/:interventionId/log/entry/:id`}>
          <InterventionLogEntry />
        </Route>

        <Route path={`${path}/intervention/:interventionId/log/quickEntry`}>
          <InterventionLogQuickEntry />
        </Route>
        <Route path={`${path}/intervention/:id/log/report`}>
          <InterventionLogReport />
        </Route>
        <Route path={`${path}/intervention/:id/log`}>
          <OneInterventionLog />
        </Route>
        <Route path={`${path}/intervention/:id/new_ic`}>
          <IncidentNewIC />
        </Route>
        <Route exact path={path}>
          <Home />
        </Route>
      </Switch>
    </Container>
  );
}
