import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useUIContext } from '../../contexts/ui/ui.context';
import ReinforcementGroups from './reinforcementGroups';
import ReinforcementsPersonnes from './reinforcementsPersonnes';
import ReinforcementCall from './reinforcementCall';
import { useQuery } from 'react-query';
import { getAllData, get } from 'src/api/api';

import Loader from 'src/shared/defaultLoader';
import ReinforcementSelectionSummary from './reinforcementSelectionSummary';
import useStore from './reinforcement.store';

export default function Reinforcements() {
  const groupQuery = () => getAllData('group');
  const { data: groupData, isLoading: groupIsLoading } = useQuery('groups', groupQuery);

  const getQueryFn = async () => await get(`board/raw`, { sort_by: 'rank' });
  const { data: membersData, isLoading: membersIsLoading } = useQuery(['reinforcementsPeople'], getQueryFn);

  const setGroupsData = useStore((state) => state.setGroupsData);
  const setMembersData = useStore((state) => state.setMembersData);

  const { t } = useTranslation();
  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('REINFORCEMENTS'));
  }, []);

  const [view, setView] = React.useState('GROUPS');

  const handleChange = (event, newType) => {
    if (newType !== null) {
      setView(newType);
    }
  };

  if (groupIsLoading || membersIsLoading) {
    return <Loader />;
  }

  setMembersData(membersData);
  setGroupsData(groupData);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ToggleButtonGroup color="primary" value={view} exclusive fullWidth onChange={handleChange}>
            <ToggleButton value="GROUPS">{t('GROUPS')}</ToggleButton>
            <ToggleButton value="PERSON">{t('PERSONS')}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <ReinforcementSelectionSummary selectedMember={1} total={membersData.length} />
        </Grid>

        <Grid item xs={12}>
          {view === 'GROUPS' ? <ReinforcementGroups /> : null}
          {view === 'PERSON' ? <ReinforcementsPersonnes /> : null}
        </Grid>
      </Grid>
      <ReinforcementCall />
    </React.Fragment>
  );
}
